import { useEffect, useState } from "react";
import { IAccountState, TActions } from "src/types/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { IClient } from "src/types/clients.types";
import * as FDN from "src/core";

const useAdminStaffs = () => {
  const [staffs, setStaffs] = useState<IAccountState[]>();
  const [selectedStaff, setSelectedStaff] = useState<IAccountState>();
  const [clients, setClients] = useState<IClient[]>();

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.getStaffs(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setStaffs(response?.body?.staffs as IAccountState[]);
        setClients(response?.body?.clients as IClient[]);
      }
    });
  };

  const onSelectStaff = (staff: IAccountState) => {
    setSelectedStaff(staff);
  };

  const onEditType = (type: "manager" | "employee") => {
    if (!selectedStaff) return;
    setSelectedStaff({ ...selectedStaff, roles: type === "manager" ? ["SMAPLY_MANAGER"] : ["SMAPLY_EMPLOYEE"] });
  };

  const onToggleClient = (clientIdentifier: string) => {
    if (!selectedStaff) return;

    const clients: string[] = selectedStaff.staffAccessToClients ?? [];

    const newClients = clients.includes(clientIdentifier)
      ? clients.filter((client) => client !== clientIdentifier)
      : [...clients, clientIdentifier];

    setSelectedStaff({ ...selectedStaff, staffAccessToClients: newClients });
  };

  const onCancel = () => {
    setSelectedStaff(undefined);
  };

  const onSave = () => {
    if (!selectedStaff) return;

    AdminApi.saveStaff(api, selectedStaff.identifier, selectedStaff).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setStaffs(response?.body?.staffs as IAccountState[]);
        setSelectedStaff(undefined);

        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminStaff.edit.form.onSave.success.title"),
          FDN.I18n.t("adminStaff.edit.form.onSave.success.text")
        );
      }
    });
  };

  const actions: TActions = {
    onSelectStaff,
    onEditType,
    onToggleClient,
    onCancel,
    onSave,
  };

  return {
    APP,
    staffs,
    selectedStaff,
    clients,
    actions,
  };
};

export default useAdminStaffs;
