import * as React from "react";
import UploaderDropzone from "../parts/UploaderDropzone";
import useFileUploaderService from "../../hooks/useFileUploader";
import { useEffect } from "react";
import useServiceCore from "src/services/CoreService";
import { FileUploadFile, IFile } from "src/types/types";

interface SingleFileUploaderProps {
  uploadType?: string;
  acceptedFileTypes: Record<string, string[]>;
  view?: "embed" | "button";
  button?: React.ReactNode;
  doNotUpload?: boolean;
  onUpdate: (url?: string, file?: IFile, uploadFile?: FileUploadFile) => void;
}

const SingleFileUploader = ({
  uploadType,
  acceptedFileTypes,
  view,
  button,
  doNotUpload,
  onUpdate: parentOnUpdate,
}: SingleFileUploaderProps) => {
  const maxFiles = 1;

  const { api } = useServiceCore();

  if (view === undefined) view = "embed";

  const onUpdate = (files: IFile[] | undefined) => {
    if (files && files.length > 0 && files[0]) {
      const fileUrl = api.getEndpoint("filesFile", {
        identifier: files[0].identifier,
      }) as string;

      parentOnUpdate(fileUrl, files[0]);
    }
  };

  const { selectedFiles, actions } = useFileUploaderService({
    uploadType,
    maxFiles,
    onUpdate,
    doNotUpload,
  });

  useEffect(() => {
    if (doNotUpload && selectedFiles && selectedFiles.length > 0) {
      parentOnUpdate(undefined, undefined, selectedFiles[0]);
    }
  }, [selectedFiles]);

  return (
    <div>
      <div className={`flex flex-col md:flex-row gap-4`}>
        <div className={`w-full`}>
          {view === "embed" && <UploaderDropzone maxFiles={maxFiles} accept={acceptedFileTypes} actions={actions} />}
          {view === "button" && (
            <UploaderDropzone
              view="button"
              button={button}
              maxFiles={maxFiles}
              accept={acceptedFileTypes}
              actions={actions}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleFileUploader;
