import * as React from "react";
import * as FDN from "src/core";
import { showUnit } from "src/core/helpers/pr.helpers";
import {
  PrFormVisibleColumn,
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  IPrProductStatusType,
} from "src/types/pr.types";
import { IProduct, IProducts } from "src/types/products.types";

const prFormVisibleColumns = [
  "amount",
  "productName",
  "unit",
  "productCategory",
  "supplier",
  "priceUnit",
  "priceTotal",
];

interface IPrPdfFormProductsListProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  products?: IProducts;
  editMode: boolean;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrPdfFormProductsList: React.FunctionComponent<IPrPdfFormProductsListProps> = ({
  showOnlyStatus,
  pr,
  products,
  editMode,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-form-products">
      <div className="__pr-form-product-head">
        <FDN.Grid full>
          <FDN.Row>
            {prFormVisibleColumns.map((type) => {
              return <ProductsListHeadItemColumn key={type} type={type} showSupplier={showSupplier} />;
            })}
          </FDN.Row>
        </FDN.Grid>
      </div>
      <div className="__pr-form-product-body">
        <ul>
          {products?.map((product) => {
            return (
              <li key={product.identifier}>
                <ProductsListItem
                  showOnlyStatus={showOnlyStatus}
                  pr={pr}
                  product={product}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface IPrPdfFormProductsListItemProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  product: IProduct;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const ProductsListItem: React.FunctionComponent<IPrPdfFormProductsListItemProps> = ({
  showOnlyStatus,
  pr,
  product,
  editMode,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-form-product">
      <FDN.Grid full>
        <FDN.Row>
          {prFormVisibleColumns.map((type) => {
            return (
              <ProductsListBodyItemColumn
                key={type}
                type={type}
                showOnlyStatus={showOnlyStatus}
                pr={pr}
                product={product}
                editMode={editMode}
                showSupplier={showSupplier}
                actions={actions}
              />
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IPrPdfFormProductsListHeadItemColumnProps {
  type: PrFormVisibleColumn;
  showSupplier: boolean;
}

const ProductsListHeadItemColumn: React.FunctionComponent<IPrPdfFormProductsListHeadItemColumnProps> = ({
  type,
  showSupplier,
}) => {
  const sm = 24;
  let md = 3;
  let textAlign = "center";

  switch (type) {
    case "amount":
      md = 2;
      break;
    case "productId":
      textAlign = "left";
      break;
    case "productName":
      md = showSupplier ? 6 : 9;
      textAlign = "left";
      break;
    case "productCategory":
      md = 4;
      textAlign = "left";
      break;
    case "supplier":
      if (!showSupplier) return null;
      textAlign = "left";
      md = 3;
      break;
    case "priceUnit":
      break;
    case "priceTotal":
      break;
  }

  const content = FDN.I18n.t(`pr.form.list.head.${type}.label`);

  return (
    <FDN.Cell sm={md} md={md}>
      <div className={`text-${textAlign}`}>{content}</div>
    </FDN.Cell>
  );
};

interface IPrPdfFormProductsListBodyItemColumnProps {
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  product: IProduct;
  type: PrFormVisibleColumn;
  editMode: boolean;
  showSupplier: boolean;
  actions: TActionsPr;
}

const ProductsListBodyItemColumn: React.FunctionComponent<IPrPdfFormProductsListBodyItemColumnProps> = ({
  showOnlyStatus,
  pr,
  product,
  type,
  editMode,
  showSupplier,
  actions,
}) => {
  const prProduct = pr.products?.find((p) => p.productIdentifier === product.identifier);

  let content = <></>;
  let textAlign = "left";
  let md = 3;

  switch (type) {
    case "amount":
      content = (
        <div className={`__pr-form-list-column type-input`}>
          {editMode === true ? (
            <input type="number" min={0} value={prProduct?.amount || ""} />
          ) : (
            <div className="text-right">
              {prProduct?.status && (
                <>
                  {showOnlyStatus ? (
                    <>
                      {prProduct?.status && (
                        <>
                          {prProduct?.status && prProduct?.status[showOnlyStatus]
                            ? prProduct?.status[showOnlyStatus] || 0
                            : 0}{" "}
                          /{" "}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {prProduct?.status && prProduct?.perfectAmount < prProduct?.amount && (
                        <>{prProduct?.perfectAmount || 0} / </>
                      )}
                    </>
                  )}
                </>
              )}
              {prProduct?.amount || ""}
            </div>
          )}
        </div>
      );
      md = 2;
      break;
    case "unit":
      textAlign = "right";
      content = <div className={`__pr-form-list-column type-text`}>{showUnit(product)}</div>;
      break;
    case "productName":
      content = (
        <div className={`__pr-form-list-column type-text`}>
          <div className="product-id">{product.productId}</div>
          <div className="product-name">{product.name}</div>
        </div>
      );
      md = showSupplier ? 6 : 9;
      break;
    case "productCategory":
      md = 4;
      content = <div className={`__pr-form-list-column type-text`}>{product.categoryName}</div>;
      break;
    case "supplier":
      if (!showSupplier) return null;

      md = 3;
      content = (
        <div className={`__pr-form-list-column type-text`}>
          {showSupplier && product.suppliers && product.suppliers[0] ? (
            <div className="__adminlist-name-subtitle" style={{ marginTop: "-5px" }}>
              <small>
                <FDN.Icon icon="truck" left /> {product.suppliers[0].name}
              </small>
            </div>
          ) : null}
        </div>
      );
      break;
    case "priceUnit":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          {FDN.formatNumber(product.priceUnit || 0)} {product.priceIsVariable && " *"}
        </div>
      );
      break;
    case "priceTotal":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          {prProduct?.priceTotal ? FDN.formatNumber(prProduct?.priceTotal || 0) : ""} {product.priceIsVariable && " *"}
        </div>
      );
      break;
  }

  return (
    <FDN.Cell sm={md} md={md} className={`text-${textAlign} ${showOnlyStatus && "opacity-50"}`}>
      {content}
    </FDN.Cell>
  );
};

export default PrPdfFormProductsList;
