import * as React from "react";
import Icon from "../Icon";

interface IStatusIndicatorProps {
  status: "yes" | "partly" | "no" | boolean;
  type: "boolean" | "trafficlight";
}

const StatusIndicator: React.FunctionComponent<IStatusIndicatorProps> = ({ status, type }) => {
  if (type === "trafficlight") return <StatusIndicatorTrafficlight status={status} />;
  else if (type === "boolean") return <StatusIndicatorBoolean status={status} />;
  return null;
};

interface IStatusIndicatorTrafficlight {
  status: "yes" | "partly" | "no" | boolean;
}

const StatusIndicatorTrafficlight: React.FunctionComponent<IStatusIndicatorTrafficlight> = ({
  status,
}) => {
  return (
    <span
      className={`__statusindicator __statusindicator-trafficlight __statusindicator-status-${status}`}
    >
      &nbsp;
    </span>
  );
};

interface IStatusIndicatorBoolean {
  status: "yes" | "partly" | "no" | boolean;
}

const StatusIndicatorBoolean: React.FunctionComponent<IStatusIndicatorBoolean> = ({ status }) => {
  if (status === true)
    return (
      <span
        className={`__statusindicator __statusindicator-boolean __statusindicator-status-${status}`}
      >
        <Icon icon="check" />
      </span>
    );
  else if (status === false)
    return (
      <span
        className={`__statusindicator __statusindicator-boolean __statusindicator-status-${status}`}
      >
        <Icon icon="times" />
      </span>
    );

  return null;
};

export default StatusIndicator;
