import * as React from "react";
import { IAdminListSchema } from "./types";
import * as FDN from "src/core";

interface IAdminListHeadProps {
  schema: IAdminListSchema;
}

const AdminListHead: React.FunctionComponent<IAdminListHeadProps> = ({ schema }) => {
  if (!schema.head) return null;

  const adminListHead = schema.head;

  return (
    <div className="__adminlist-head">
      <FDN.Grid full>
        <FDN.Row margin={schema.rowMargin}>
          {adminListHead.columns.map((column, index) => {
            let sm = 24;
            let md = 24;
            let lg = 24;

            if (typeof column.size === "number") {
              sm = column.size;
              md = column.size;
              lg = column.size;
            } else {
              if (column.size.sm) {
                sm = column.size.sm;
                md = column.size.sm;
                lg = column.size.sm;
              }
              if (column.size.md) {
                md = column.size.md;
                lg = column.size.md;
              }
              if (column.size.lg) {
                lg = column.size.lg;
              }
            }

            let borderLeftClass = "";
            if (column.borderLeft) {
              if (column.borderLeft === true) borderLeftClass = "border-left-solid";
              else borderLeftClass = `border-left-${column.borderLeft}`;
            }

            let borderRightClass = "";
            if (column.borderRight) {
              if (column.borderRight === true) borderRightClass = "border-right-solid";
              else borderRightClass = `border-right-${column.borderRight}`;
            }

            return (
              <FDN.Cell
                sm={sm}
                md={md}
                lg={lg}
                key={index}
                className={`__adminlist-cell ${borderLeftClass} ${borderRightClass} ${
                  column.textAlign ? `text-${column.textAlign}` : ``
                }`}
              >
                {column.tooltip ? (
                  <FDN.ToolTip tooltip={column.tooltip}>{column.content || null}</FDN.ToolTip>
                ) : (
                  <>{column.content || null}</>
                )}
              </FDN.Cell>
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default AdminListHead;
