import * as React from "react";
import * as FDN from "src/core";
import TabInformation from "./edit/tabs/TabInformation";
import TabClients from "./edit/tabs/TabClients";
import { IClientSector } from "src/types/clients.types";
import { AdminSectorsActions } from "src/services/admin/useAdminSectors";

const tabs: FDN.ITabs = {
  information: {
    label: FDN.I18n.t("adminSectors.edit.tabs.information.label"),
    icon: <FDN.Icon icon="id-card-o" />,
  },
  clients: {
    label: FDN.I18n.t("adminSectors.edit.tabs.clients.label"),
    icon: <FDN.Icon icon="users" />,
  },
};

interface IPopupSectorEditProps {
  sector: IClientSector;
  missingFields: string[];
  actions: AdminSectorsActions;
}

const PopupSectorEdit: React.FunctionComponent<IPopupSectorEditProps> = ({ sector, missingFields, actions }) => {
  const [selectedTab, setSelectedTab] = React.useState<string>("information");

  return (
    <div className="admin-edit-sector">
      <FDN.Popup
        size="small"
        title={sector.identifier === "new" ? FDN.I18n.t("adminSectors.edit.new.title") : sector.name}
        onClose={actions.onCancel}
      >
        {sector.identifier !== "new" && <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />}

        <div className="pt-4">
          {selectedTab === "information" && (
            <TabInformation sector={sector} missingFields={missingFields} actions={actions} />
          )}
          {selectedTab === "clients" && <TabClients sector={sector} />}
        </div>
      </FDN.Popup>
    </div>
  );
};

export default PopupSectorEdit;
