import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumn,
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  IPrProductStatus,
  IPrProductStatusType,
} from "src/types/pr.types";
import { IProduct, IProducts } from "src/types/products.types";
import InputNumber from "./InputNumber";
import PrProductDropdownMenu from "./PrProductDropdownMenu";
import { showUnit } from "src/core/helpers/pr.helpers";

interface IPrFormProductsListProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  products?: IProducts;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFormProductsList: React.FunctionComponent<IPrFormProductsListProps> = ({
  location,
  showOnlyStatus,
  pr,
  products,
  editMode,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-form-products">
      <div className="__pr-form-product-head">
        <FDN.Grid full>
          <FDN.Row>
            {prFormVisibleColumns.map((type) => {
              return (
                <ProductsListHeadItemColumn key={type} type={type} location={location} showSupplier={showSupplier} />
              );
            })}
          </FDN.Row>
        </FDN.Grid>
      </div>
      <div className="__pr-form-product-body">
        <ul>
          {products?.map((product, index) => {
            return (
              <li key={product.identifier}>
                <div className="__pr-form-product-body-number">#{index + 1}</div>
                <FDN.Box noPadding noMargin>
                  <ProductsListItem
                    location={location}
                    showOnlyStatus={showOnlyStatus}
                    pr={pr}
                    product={product}
                    editMode={editMode}
                    prFormVisibleColumns={prFormVisibleColumns}
                    showSupplier={showSupplier}
                    actions={actions}
                  />
                </FDN.Box>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface IPrFormProductsListItemProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  product: IProduct;
  editMode: boolean;
  prFormVisibleColumns: PrFormVisibleColumns;
  showSupplier: boolean;
  actions: TActionsPr;
}

const ProductsListItem: React.FunctionComponent<IPrFormProductsListItemProps> = ({
  location,
  showOnlyStatus,
  pr,
  product,
  editMode,
  prFormVisibleColumns,
  showSupplier,
  actions,
}) => {
  return (
    <div className="__pr-form-product" id={`pr_row_${product.identifier}_d`}>
      <FDN.Grid full>
        <FDN.Row>
          {prFormVisibleColumns.map((type) => {
            return (
              <ProductsListBodyItemColumn
                key={type}
                type={type}
                location={location}
                showOnlyStatus={showOnlyStatus}
                pr={pr}
                product={product}
                editMode={editMode}
                showSupplier={showSupplier}
                actions={actions}
              />
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IPrFormProductsListHeadItemColumnProps {
  type: PrFormVisibleColumn;
  location: "admin" | "client";
  showSupplier: boolean;
}

const ProductsListHeadItemColumn: React.FunctionComponent<IPrFormProductsListHeadItemColumnProps> = ({
  type,
  location,
  showSupplier,
}) => {
  const sm = 24;
  let md = 3;
  let textAlign = "center";

  switch (type) {
    case "amount":
      md = 3;
      break;
    case "unit":
      md = 2;
      break;
    case "productId":
      textAlign = "left";
      break;
    case "productName":
      md = showSupplier ? 6 : 9;
      textAlign = "left";
      break;
    case "productCategory":
      md = 4;
      textAlign = "left";
      break;
    case "supplier":
      if (!showSupplier) return null;
      textAlign = "left";
      md = 3;
      break;
    case "priceUnit":
      break;
    case "priceTotal":
      break;
  }

  const content = FDN.I18n.t(`pr.form.list.head.${type}.label`);

  return (
    <FDN.Cell sm={sm} md={md}>
      <div className={`text-${textAlign}`}>{content}</div>
    </FDN.Cell>
  );
};

interface IPrFormProductsListBodyItemColumnProps {
  location: "admin" | "client";
  showOnlyStatus?: IPrProductStatusType;
  pr: IPurchaseRequisition;
  product: IProduct;
  type: PrFormVisibleColumn;
  editMode: boolean;
  showSupplier: boolean;
  actions: TActionsPr;
}

const ProductsListBodyItemColumn: React.FunctionComponent<IPrFormProductsListBodyItemColumnProps> = ({
  location,
  showOnlyStatus,
  pr,
  product,
  type,
  editMode,
  showSupplier,
  actions,
}) => {
  const prProduct = pr.products?.find((p) => p.productIdentifier === product.identifier);

  let content = <></>;
  let textAlign = "left";
  let sm = 24;
  let md = 3;
  let hideColumnForMobile = false;

  switch (type) {
    case "amount":
      sm = 12;
      md = 3;
      content = (
        <div className={`__pr-form-list-column type-input ${showOnlyStatus && "opacity-50"}`}>
          {editMode === true ? (
            <>
              {/* <input
              type="number"
              min={0}
              value={prProduct?.amount || ""}
              onChange={(e) => {
                actions.updatePrProduct(
                  product.identifier,
                  e.target ? e.target.valueAsNumber : undefined
                );
              }}
            /> */}
              <InputNumber
                horizontal
                value={prProduct ? prProduct.amount : 0}
                onUpdate={(value) => actions.updatePrProduct(product.identifier, value)}
              />
            </>
          ) : (
            <div className="text-right">
              {showOnlyStatus ? (
                <>
                  {prProduct?.status && (
                    <>
                      {prProduct?.status && prProduct?.status[showOnlyStatus]
                        ? prProduct?.status[showOnlyStatus] || 0
                        : 0}{" "}
                      /{" "}
                    </>
                  )}
                </>
              ) : (
                <>
                  {prProduct?.status && prProduct?.perfectAmount < prProduct?.amount && (
                    <>{prProduct?.perfectAmount || 0} / </>
                  )}
                </>
              )}
              {prProduct?.amount || ""}
            </div>
          )}
        </div>
      );
      break;
    case "productName":
      content = (
        <div className="__pr-form-list-column type-text">
          <div className="w-full flex gap-0">
            <div className={`flex-grow ${showOnlyStatus && "opacity-50"}`}>
              <div className="product-id">{product.productId}</div>
              <div className="product-name">
                {product.name}
                {product.description && (
                  <span className="pl-1">
                    <FDN.ToolTip tooltip={product.description}>
                      <FDN.Icon icon="info-circle" />
                    </FDN.ToolTip>
                  </span>
                )}
              </div>
              {product.description && (
                <div className="-mt-0.5 text-xs text-gray-500">
                  {product.description.length > 18 ? product.description.substring(0, 15) + "..." : product.description}
                </div>
              )}
            </div>
            <div>
              {prProduct && location === "admin" && pr.status === "PROCESS" ? (
                <>
                  <PrProductDropdownMenu
                    pr={pr}
                    product={prProduct}
                    productName={product.name || ""}
                    location={location}
                    menuItems={["NOT_IN_STOCK"]}
                  >
                    <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                      <FDN.Icon icon="ellipsis-h" />
                    </div>
                  </PrProductDropdownMenu>
                </>
              ) : null}
              {prProduct &&
              location === "client" &&
              showOnlyStatus !== "NOT_IN_STOCK" &&
              (pr.status === "DELIVERY" || pr.status === "COMPLETED") ? (
                <>
                  <PrProductDropdownMenu
                    pr={pr}
                    product={prProduct}
                    productName={product.name || ""}
                    location={location}
                    menuItems={["SHORTAGE", "QUALITY"]}
                  >
                    <div className="cursor-pointer px-2 py-1 rounded-md hover:bg-primaryHover hover:text-black bg">
                      <FDN.Icon icon="ellipsis-h" />
                    </div>
                  </PrProductDropdownMenu>
                </>
              ) : null}
            </div>
          </div>
        </div>
      );

      md = showSupplier ? 6 : 9;
      break;
    case "unit":
      sm = 12;
      md = 2;
      textAlign = "right";
      content = (
        <div className={`__pr-form-list-column type-text type-unit ${showOnlyStatus && "opacity-50"}`}>
          {prProduct?.unit ?? showUnit(product)}
        </div>
      );
      break;
    case "productCategory":
      hideColumnForMobile = true;
      md = 4;
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>
          {product.categoryName}
        </div>
      );
      break;
    case "supplier":
      if (!showSupplier) return null;

      md = 3;
      content = (
        <div className={`__pr-form-list-column type-text ${showOnlyStatus && "opacity-50"}`}>
          {showSupplier && product.suppliers && product.suppliers[0] ? (
            <div className="__adminlist-name-subtitle" style={{ marginTop: "-5px" }}>
              <small>
                <FDN.Icon icon="truck" left /> {product.suppliers[0].name}
              </small>
            </div>
          ) : null}
        </div>
      );
      break;
    case "priceUnit":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          <span className="show-for-small-only">{FDN.I18n.t(`pr.form.list.head.${type}.label`)}:&nbsp;</span>
          {product.priceIsVariable ? (
            <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
              <span style={{ cursor: "help" }}>{FDN.formatNumber(product.priceUnit || 0)} *</span>
            </FDN.ToolTip>
          ) : (
            <>{FDN.formatNumber(product.priceUnit || 0)}</>
          )}
        </div>
      );
      break;
    case "priceTotal":
      content = (
        <div className={`__pr-form-list-column type-price ${showOnlyStatus && "hidden"}`}>
          <span className="show-for-small-only">{FDN.I18n.t(`pr.form.list.head.${type}.label`)}:&nbsp;</span>
          {product.priceIsVariable ? (
            <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
              <span style={{ cursor: "help" }}>
                {prProduct?.priceTotal ? FDN.formatNumber(prProduct?.priceTotal || 0) : "0,00"} *
              </span>
            </FDN.ToolTip>
          ) : (
            <>{prProduct?.priceTotal ? FDN.formatNumber(prProduct?.priceTotal || 0) : "0,00"}</>
          )}
        </div>
      );
      break;
  }

  return (
    <FDN.Cell
      sm={sm}
      md={md}
      className={`text-${textAlign} ${hideColumnForMobile === true ? `hide-for-small-only` : ``}`}
    >
      {content}
    </FDN.Cell>
  );
};

export default PrFormProductsList;
