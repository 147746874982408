import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { TActionsAdminProducts } from "src/services/admin/AdminProductsService";
import { IProduct, IProductBaseUnit, IProductCategories, IProductUnits, ISuppliers } from "src/types/products.types";

interface IPopupProductEditProps {
  product: IProduct;
  missingFieldsProduct?: string[];
  categories: IProductCategories;
  productUnits: IProductUnits;
  baseUnits: IProductBaseUnit[];
  suppliers: ISuppliers;
  actions: TActionsAdminProducts;
}

const PopupProductEdit: React.FunctionComponent<IPopupProductEditProps> = ({
  product,
  missingFieldsProduct,
  categories,
  productUnits,
  baseUnits,
  suppliers,
  actions,
}) => {
  const [newUnit, setNewUnit] = React.useState<string>();

  const selectValuesCategories: { [key: string]: string } = {
    none: "---",
  };
  for (const category of categories) {
    if (category.name) selectValuesCategories[category.identifier] = category.name;
  }

  const selectValuesUnits: { [key: string]: string } = {
    none: "",
  };

  for (const productUnit of productUnits) {
    selectValuesUnits[`unit_${productUnit}`] = productUnit;
  }
  selectValuesUnits.add = FDN.I18n.t("adminProducts.popup.product.form.addUnit.label");

  const selectValuesSuppliers: { [key: string]: string } = {
    none: "",
  };

  for (const supplier of suppliers) {
    let label = supplier.name;
    if (supplier.zip || supplier.city) label += ` (${supplier.zip} ${supplier.city})`;
    selectValuesSuppliers[`supplier_${supplier.identifier}`] = label;
  }

  const selectedSupplier =
    product.supplierIdentifiers && product.supplierIdentifiers[0] ? product.supplierIdentifiers[0] : "none";

  const selectValuesBaseUnits: { [key: string]: string } = {
    none: "",
  };

  for (const baseUnit of baseUnits) {
    selectValuesBaseUnits[`baseunit_${baseUnit.identifier}`] = baseUnit.name;
  }

  return (
    <div className="__admin-products-popup-edit-product">
      <FDN.Popup
        size="medium"
        title={product.identifier === "new" ? FDN.I18n.t("adminProducts.popup.product.title.new") : product.name}
        onClose={actions.hidePopupEditProduct}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell md={12}>
              <FDN.Input
                type="select"
                selectValues={selectValuesCategories}
                value={product.categoryIdentifier}
                label={FDN.I18n.t("adminProducts.popup.product.form.category.label")}
                isMissing={missingFieldsProduct?.includes("categoryIdentifier")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("categoryIdentifier", value);
                }}
              />
            </FDN.Cell>
            <FDN.Cell md={12}>
              <FDN.Input
                type="select"
                selectValues={selectValuesSuppliers}
                value={`supplier_${selectedSupplier}`}
                label={FDN.I18n.t("adminProducts.popup.product.form.supplier.label")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("supplierIdentifiers", value.replace("supplier_", ""));
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                value={
                  product.identifier === "new"
                    ? FDN.I18n.t("adminClients.popup.client.form.clientNr.automatic")
                    : product.productId
                }
                label={FDN.I18n.t("adminProducts.popup.product.form.productId.label")}
                isMissing={missingFieldsProduct?.includes("productId")}
                editMode={false}
                onUpdate={(value) => {
                  actions.onEditProduct("productId", value);
                }}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                value={product.supplierItemNumber}
                label={FDN.I18n.t("adminProducts.popup.product.form.supplierItemNumber.label")}
                isMissing={missingFieldsProduct?.includes("supplierItemNumber")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("supplierItemNumber", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24}>
              <FDN.Input
                type="text"
                value={product.name}
                label={FDN.I18n.t("adminProducts.popup.product.form.name.label")}
                isMissing={missingFieldsProduct?.includes("name")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("name", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell md={24}>
              <FDN.Input
                type="textarea"
                rows={3}
                value={product.internalDescription}
                label={FDN.I18n.t("adminProducts.popup.product.form.internalDescription.label")}
                isMissing={missingFieldsProduct?.includes("internalDescription")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("internalDescription", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell md={24}>
              <FDN.Input
                type="text"
                max={40}
                value={product.description}
                label={FDN.I18n.t("adminProducts.popup.product.form.description.label")}
                isMissing={missingFieldsProduct?.includes("description")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("description", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={8} md={4}>
              <FDN.Input
                type="number"
                value={product.amount}
                label={FDN.I18n.t("adminProducts.popup.product.form.amount.label")}
                isMissing={missingFieldsProduct?.includes("amount")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("amount", value);
                }}
              />
            </FDN.Cell>

            <>
              <FDN.Cell sm={8} md={6}>
                <FDN.Input
                  type="text"
                  value={product.package}
                  label={FDN.I18n.t("adminProducts.popup.product.form.package.label")}
                  isMissing={missingFieldsProduct?.includes("package")}
                  editMode={true}
                  onUpdate={(value) => {
                    actions.onEditProduct("package", value);
                  }}
                />
              </FDN.Cell>
              <FDN.Cell sm={8} md={6}>
                <FDN.Input
                  type="select"
                  selectValues={selectValuesBaseUnits}
                  value={`baseunit_${product.baseUnitIdentifier}`}
                  label={FDN.I18n.t("adminProducts.popup.product.form.baseUnit.label")}
                  isMissing={missingFieldsProduct?.includes("baseUnitIdentifier")}
                  editMode={true}
                  onUpdate={(value) => {
                    actions.onEditProduct("baseUnitIdentifier", value.replace("baseunit_", ""));
                  }}
                />
              </FDN.Cell>
            </>

            {/* <FDN.Cell sm={16} md={8}>
                {newUnit === undefined ? (
                  <FDN.Input
                    type="select"
                    selectValues={selectValuesUnits}
                    value={`unit_${product.unit}`}
                    label={FDN.I18n.t("adminProducts.popup.product.form.unit.label")}
                    isMissing={missingFieldsProduct?.includes("unit")}
                    editMode={true}
                    onUpdate={(value) => {
                      if (value === "add") setNewUnit("");
                      else actions.onEditProduct("unit", value.replace("unit_", ""));
                    }}
                  />
                ) : (
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                    <div style={{ flexGrow: 1 }}>
                      <FDN.Input
                        type="text"
                        value={newUnit}
                        label={FDN.I18n.t("adminProducts.popup.product.form.unit.label")}
                        isMissing={missingFieldsProduct?.includes("unit")}
                        editMode={true}
                        onUpdate={(value) => {
                          setNewUnit(value);
                          actions.onEditProduct("unit", value);
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="clickable-text"
                        onClick={() => {
                          setNewUnit(undefined);
                          actions.onEditProduct("unit", "");
                        }}
                        style={{ lineHeight: "44px" }}
                      >
                        <FDN.Icon icon="times" />
                      </button>
                    </div>
                  </div>
                )}
              </FDN.Cell> */}

            <FDN.Cell sm={24} md={8}>
              <FDN.Input
                type="currency"
                options={{ currency: Config.get("currency") as string }}
                value={product.priceUnit}
                label={FDN.I18n.t("adminProducts.popup.product.form.priceUnit.label")}
                isMissing={missingFieldsProduct?.includes("priceUnit")}
                editMode={true}
                onUpdate={(value) => {
                  actions.onEditProduct("priceUnit", value);
                }}
              />
              <div className="text-center" style={{ paddingTop: "10px" }}>
                <FDN.Input
                  type="checkbox"
                  value={product.priceIsVariable}
                  label={FDN.I18n.t("adminProducts.popup.product.form.priceIsVariable.label")}
                  editMode={true}
                  onUpdate={(value) => {
                    actions.onEditProduct("priceIsVariable", value);
                  }}
                />
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        <div>&nbsp;</div>
        <FDN.FormButtons
          onSave={actions.onSaveProduct}
          onCancel={actions.hidePopupEditProduct}
          onDelete={actions.onDeleteProduct}
        />
      </FDN.Popup>
    </div>
  );
};

export default PopupProductEdit;
