import * as React from "react";
import * as FDN from "src/core";
import UrlService from "src/core/UrlService";

type UserroleMember = { [key: string]: any };

interface IUserRoleMembersProps {
  members: UserroleMember[];
}

const UserRoleMembers: React.FunctionComponent<IUserRoleMembersProps> = ({ members }) => {
  return (
    <>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Box small noMargin>
          <div className="text-center">
            {FDN.I18n.t("adminUserroles.list.membersCount.longText", {
              members: `${members.length} ${FDN.I18n.t(
                `adminUserroles.list.membersCount.${members.length === 1 ? `member` : `members`}`
              )}`,
            })}
          </div>
        </FDN.Box>
      </FDN.SingleRowCell>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          {members.map((member) => {
            return (
              <FDN.Cell sm={24} md={8} lg={6} key={member.identifier}>
                <div className="center-vertically">
                  <FDN.Box
                    small
                    style={{ width: "100%" }}
                    onClick={() =>
                      (window.location.href = UrlService.url("admin.accounts.account", {
                        identifier: member.identifier,
                      }))
                    }
                  >
                    <FDN.Avatar user={member} size={28} />{" "}
                    <span style={{ paddingLeft: "6px" }}>{member.displayname}</span>
                  </FDN.Box>
                </div>
              </FDN.Cell>
            );
          })}
        </FDN.Row>
      </FDN.Grid>
    </>
  );
};

export default UserRoleMembers;
