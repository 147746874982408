import * as React from "react";
import ReactSwitch from "react-switch";
import Icon from "../Icon";

const sizes = {
  small: { h: 15, w: 28 },
  medium: { h: 19, w: 40 },
  big: { h: 25, w: 48 },
};

interface ISwitchProps {
  value: boolean;
  editMode?: boolean;
  label?: string;
  labelPosition?: "left" | "right";
  size?: "small" | "medium" | "big";
  onUpdate?: (value: boolean) => void;
}

const Switch: React.FunctionComponent<ISwitchProps> = (props) => {
  let { value, labelPosition, size } = props;
  const { editMode, label, onUpdate } = props;

  if (!value) value = false;
  if (!labelPosition) labelPosition = "right";
  if (!size) size = "medium";

  const onToggle = () => {
    if (onUpdate) onUpdate(!value);
  };

  return (
    <span className={`__switch __switch-size-${size}`}>
      {editMode === true ? (
        <SwitchEditMode
          value={value}
          label={label}
          labelPosition={labelPosition}
          size={size}
          onToggle={onToggle}
        />
      ) : (
        <SwitchViewMode value={value} label={label} labelPosition={labelPosition} />
      )}
    </span>
  );
};

interface ISwitchView {
  value: boolean;
  label?: string;
  labelPosition: "left" | "right";
}

const SwitchViewMode: React.FunctionComponent<ISwitchView> = ({ value, label, labelPosition }) => {
  return (
    <span className="__switch-view-mode">
      {labelPosition === "left" && <Label label={label} labelPosition={labelPosition} />}
      {value === true ? (
        <span className="__switch-view-mode-checkbox checked">
          <Icon icon="check-square-o" />
        </span>
      ) : (
        <span className="__switch-view-mode-checkbox notchecked">
          <Icon icon="square-o" />
        </span>
      )}
      {labelPosition === "right" && <Label label={label} labelPosition={labelPosition} />}
    </span>
  );
};

interface ISwitchEdit {
  value: boolean;
  label?: string;
  labelPosition: "left" | "right";
  size: "small" | "medium" | "big";
  onToggle: () => void;
}

const SwitchEditMode: React.FunctionComponent<ISwitchEdit> = ({
  value,
  label,
  labelPosition,
  size,
  onToggle,
}) => {
  return (
    <span className="__switch-edit-mode">
      {labelPosition === "left" && <Label label={label} labelPosition={labelPosition} />}
      <ReactSwitch
        checked={value}
        height={sizes[size].h}
        width={sizes[size].w}
        onChange={() => onToggle()}
      />
      {labelPosition === "right" && <Label label={label} labelPosition={labelPosition} />}
    </span>
  );
};

interface ISwitchLabel {
  label?: string;
  labelPosition: "right" | "left";
}

const Label: React.FunctionComponent<ISwitchLabel> = ({ label, labelPosition }) => {
  if (!label) return null;

  return <span className={`__switch-label __switch-label-position-${labelPosition}`}>{label}</span>;
};

export default Switch;
