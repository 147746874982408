import * as FDN from "src/core";
import { FileUploadFile, IFile } from "src/types/types";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import FilesApi from "src/api/FilesApi";

export interface FileUploaderActions {
  selectFiles: (files: File[]) => void;
  resetSelectedFiles: () => void;
}

interface FileUploader {
  uploadType?: string;
  maxFiles?: number;
  doNotUpload?: boolean;
  onUpdate: (files: IFile[] | undefined) => void;
}

const useFileUploaderService = ({ uploadType, maxFiles, doNotUpload, onUpdate }: FileUploader) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileUploadFile[] | undefined>(undefined);

  const { NOTIFICATIONS, api } = useServiceCore();

  const { uploadFile } = FilesApi;

  if (!uploadType) uploadType = "misc";

  useEffect(() => {
    if (doNotUpload) return;

    console.log("updating", selectedFiles);

    if (selectedFiles) uploadSelectedFiles(selectedFiles);
  }, [selectedFiles]);

  const selectFiles = (files: File[] | undefined) => {
    console.log("setselectedfiles", files);

    if (!files) return;

    const uploadFiles: FileUploadFile[] = [];

    if (maxFiles) files = files.slice(0, maxFiles);

    for (const file of files) {
      const fileImageUpload: FileUploadFile = {
        uuid: v4(),
        name: file.name,
        size: file.size,
        original: file,
      };

      uploadFiles.push(fileImageUpload);
    }

    console.log("setselectedfiles uüploadfiles", uploadFiles);

    setSelectedFiles((prev) => [...(prev ?? []), ...uploadFiles]);
  };

  const resetSelectedFiles = () => {
    setSelectedFiles(undefined);
  };

  const uploadSelectedFiles = async (files: FileUploadFile[]) => {
    console.log("FILES", files);

    if (!files || files.length === 0) return;

    const response = await uploadFile(api, uploadType ?? "misc", files[0]);
    console.log("STATUS", response?.statusCode, response?.body);

    if (response?.statusCode === StatusCode.SUCCESS) {
      if (response?.body?.files) onUpdate(response.body.files as IFile[]);
      // if (body?.urls) onUpdate(body.urls);

      NOTIFICATIONS.showNotification(
        "success",
        FDN.I18n.t("main.uploader.singleFile.onSave.success.title"),
        FDN.I18n.t("main.uploader.singleFile.onSave.success.text")
      );
    } else {
      NOTIFICATIONS.showNotification(
        "danger",
        FDN.I18n.t("main.uploader.singleFile.onSave.error.title"),
        FDN.I18n.t("main.uploader.singleFile.onSave.error.text")
      );
    }
  };

  const actions: FileUploaderActions = {
    selectFiles,
    resetSelectedFiles,
  };

  return { selectedFiles, isLoading, actions };
};

export default useFileUploaderService;
