import React, { useState } from "react";

import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import useServiceCore from "../CoreService";
import { I18n } from "src/core";
import { ISupplier, ISuppliers } from "src/types/products.types";
import { cloneDeep } from "lodash";
import { SuppliersSearchProps } from "src/types/suppliers.types";

const initSearch: SuppliersSearchProps = {
  text: "",
  city: "",
  sortBy: "name_asc",
};

export interface TActionsAdminSuppliers {
  updateSearch: <P extends keyof SuppliersSearchProps, V extends SuppliersSearchProps[P]>(
    property: P,
    value: V
  ) => void;
  resetSearch: () => void;
  getFilteredSuppliers: () => ISuppliers;
  showPopupEditSupplier: (supplier: ISupplier | "new") => void;
  onEdit: <P extends keyof ISupplier, V extends ISupplier[P]>(property: P, value: V) => void;
  onDelete: () => void;
  onCancel: () => void;
  onSave: () => void;
}

const useServiceAdminSuppliers = () => {
  const [suppliers, setSuppliers] = useState<ISuppliers>();
  const [virginSupplier, setVirginSupplier] = useState<ISupplier>();

  const [editSupplier, setEditSupplier] = useState<ISupplier>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [search, setSearch] = useState<SuppliersSearchProps>(cloneDeep(initSearch));
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.getSuppliers(api).then((response) => {
      APP.setPageTitle(I18n.t("adminSuppliers.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setSuppliers(response?.body?.suppliers as ISuppliers);
        setVirginSupplier(response?.body?.virginSupplier as ISupplier);
      }
    });
  };

  function updateSearch<P extends keyof SuppliersSearchProps, V extends SuppliersSearchProps[P]>(
    property: P,
    value: V
  ) {
    if (!search) return;
    const updatedSearch = cloneDeep(search);
    updatedSearch[property] = value;
    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    const updatedSearch = cloneDeep(initSearch);
    if (search.sortBy) updatedSearch.sortBy = search.sortBy;
    setSearch(updatedSearch);
  };

  const getFilteredSuppliers = () => {
    const filteredSuppliers: ISuppliers = [];

    if (!suppliers) return filteredSuppliers;
    if (!search) return suppliers;

    for (const supplier of suppliers) {
      let addToList = true;

      if (search.text) {
        addToList = false;

        if (supplier.name.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.zip?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.city?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.country?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.contactName?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.contactEmail?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
        if (supplier.contactPhone?.toLowerCase().includes(search.text.toLowerCase())) addToList = true;
      }

      if (addToList === true && search.city) {
        addToList = false;

        if (supplier.city?.toLowerCase() === search.city.toLowerCase()) addToList = true;
      }

      if (addToList === true) filteredSuppliers.push(supplier);
    }

    if (search.sortBy) sortSuppliers(filteredSuppliers, search.sortBy);

    return filteredSuppliers;
  };

  const sortSuppliers = (suppliers: ISuppliers, sortBy: string): ISuppliers => {
    const [sortByKey, sortByDirection] = sortBy.split("_");

    let property = "";
    if (sortByKey === "name") property = "name";
    else if (sortByKey === "zip") property = "zip";
    else if (sortByKey === "city") property = "city";
    else if (sortByKey === "contactName") property = "contactName";
    else if (sortByKey === "contactEmail") property = "contactEmail";

    if (!property || (sortByDirection !== "asc" && sortByDirection !== "desc")) return suppliers;

    const sorted = suppliers.sort((a, b) => {
      if (property === "name") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "zip") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "city") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "contactName") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "contactEmail") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else return 1;
    });

    return sorted;
  };

  const showPopupEditSupplier = (supplier: ISupplier | "new") => {
    if (supplier === "new") {
      setEditSupplier(cloneDeep(virginSupplier));
    } else {
      setEditSupplier(cloneDeep(supplier));
    }
  };

  function onEdit<P extends keyof ISupplier, V extends ISupplier[P]>(property: P, value: V) {
    if (!editSupplier) return;

    editSupplier[property] = value;

    setEditSupplier(cloneDeep(editSupplier));
    setUnsavedChanges(true);
  }

  const onDelete = () => {
    if (!editSupplier) return;

    const doDelete = () => {
      AdminApi.deleteSupplier(api, editSupplier.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          setSuppliers(response?.body?.suppliers as ISuppliers);
          setEditSupplier(undefined);
          setUnsavedChanges(false);
          NOTIFICATIONS.hideDialog();

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminSuppliers.onDelete.success.title"),
            I18n.t("adminSuppliers.onDelete.success.text")
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminSuppliers.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doDelete(),
        },
      ],
    });
  };

  const onCancel = () => {
    if (!editSupplier) return;

    const doCancel = () => {
      setEditSupplier(undefined);
      setUnsavedChanges(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!editSupplier) return;

    const missingFields: string[] = [];
    if (!editSupplier.name) missingFields.push("name");

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      return;
    }

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveSupplier(api, editSupplier.identifier, editSupplier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminSuppliers.onSave.success.title"),
          I18n.t("adminSuppliers.onSave.success.text")
        );
        setSuppliers(response?.body?.suppliers as ISuppliers);
        setEditSupplier(undefined);
        setUnsavedChanges(false);
      }
    });
    NOTIFICATIONS.hideSaving();
  };

  const actions: TActionsAdminSuppliers = {
    updateSearch,
    resetSearch,
    getFilteredSuppliers,
    showPopupEditSupplier,
    onEdit,
    onDelete,
    onCancel,
    onSave,
  };

  return {
    suppliers,
    editSupplier,
    missingFields,
    search,
    actions,
    APP,
  };
};

export default useServiceAdminSuppliers;
