import * as React from "react";
import * as FDN from "src/core";
import Api from "src/components/../api/Api";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";

const Erro404: React.FunctionComponent = () => {
  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  React.useEffect(() => {
    const api = new Api(APP, NOTIFICATIONS);
    api.get("hello");
  }, []);

  return (
    <FDN.LayoutWithTopbar page="error404">
      <FDN.PageContent>
        <h1>{FDN.I18n.t("errors.error404.title")}</h1>
        <div dangerouslySetInnerHTML={{ __html: FDN.I18n.t("errors.error404.text") }} />
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default Erro404;
