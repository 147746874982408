import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminSuppliers } from "src/services/admin/AdminSuppliersService";
import { ISupplier } from "src/types/products.types";
import TabInformation from "./edit/tabs/TabInformation";
import TabProducts from "./edit/tabs/TabProducts";

const tabs: FDN.ITabs = {
  information: {
    label: FDN.I18n.t("adminSuppliers.edit.tabs.information.label"),
    icon: <FDN.Icon icon="id-card-o" />,
  },
  products: {
    label: FDN.I18n.t("adminSuppliers.edit.tabs.products.label"),
    icon: <FDN.Icon icon="cubes" />,
  },
};

interface IPopupSupplierEditProps {
  supplier: ISupplier;
  missingFields: string[];
  actions: TActionsAdminSuppliers;
}

const PopupSupplierEdit: React.FunctionComponent<IPopupSupplierEditProps> = ({ supplier, missingFields, actions }) => {
  const [selectedTab, setSelectedTab] = React.useState<string>("information");

  return (
    <div className="admin-edit-supplier">
      <FDN.Popup
        size="large"
        title={supplier.identifier === "new" ? FDN.I18n.t("adminSuppliers.edit.new.title") : supplier.name}
        onClose={actions.onCancel}
      >
        {supplier.identifier !== "new" && <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />}
        <div className="pt-4">
          {selectedTab === "information" && (
            <TabInformation supplier={supplier} missingFields={missingFields} actions={actions} />
          )}
          {selectedTab === "products" && (
            <TabProducts supplier={supplier} missingFields={missingFields} actions={actions} />
          )}
        </div>
      </FDN.Popup>
    </div>
  );
};

export default PopupSupplierEdit;
