import * as React from "react";
import * as FDN from "src/core";
import { Link } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { hasAccessToAdminArea } from "src/core/AdminService/helpers";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";

interface IAdminNavigationProps {
  selected?: string;
}

const AdminNavigation: React.FunctionComponent<IAdminNavigationProps> = ({ selected }) => {
  const APP = React.useContext(AppContext);

  const user = APP.getUser();
  if (!user) return null;

  const adminNavItems = Config.get("admin.nav.items") as {
    [key: string]: { [key: string]: any };
  };

  return (
    <>
      <div className="__admin-nav">
        <div className="__admin-nav-title">{FDN.I18n.t("adminAdmin.nav.title")}</div>
        <ul>
          {Object.keys(adminNavItems).map((adminNavItemKey) => {
            const adminNavItem = adminNavItems[adminNavItemKey];
            if (!hasAccessToAdminArea(user, adminNavItemKey)) return null;
            return (
              <li key={adminNavItemKey} className={`${selected === adminNavItemKey ? `selected` : ``}`}>
                <Link to={UrlService.url(adminNavItem.route)}>
                  {FDN.I18n.t(`adminAdmin.nav.item.${adminNavItemKey}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default AdminNavigation;
