import * as React from "react";
import { IInputEditProps } from "../types";
import countriesDE from "../../../../config/countries_de.json";
import countriesEN from "../../../../config/countries_en.json";

type ICountries = Array<{ [key: string]: any }>;

const InputCountry: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, actions } = props;

  let countries: ICountries = countriesDE;
  if (props?.options?.locale === "en-US") countries = countriesEN;

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <select value={value || ""} onChange={(e) => actions.onEdit(e.target.value)}>
        <option value=""></option>
        {(countries as ICountries).map((country, index) => {
          return (
            <option key={index} value={country.name}>
              {country.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default InputCountry;
