import * as React from "react";
import { IShowValueProps } from "../types";

const ShowValue: React.FunctionComponent<IShowValueProps> = (props) => {
  if (props.type && props.type === "i18ntext") {
    if (!props.options?.availableLanguages) return null;

    const valueObj = props.value as { [key: string]: string };

    return (
      <div className="__input-show-value">
        {Object.keys(props.options.availableLanguages).map((languageKey) => {
          return (
            <div key={languageKey}>
              {props.options?.availableLanguages[languageKey].icon}{" "}
              {valueObj[languageKey] || <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="__input-show-value">
      {props.value || <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
    </div>
  );
};

export default ShowValue;
