import * as React from "react";
import { isEmail } from "../../../../helpers/validation";
import { IInputEditProps } from "../types";
import { NumericFormat } from "react-number-format";

const InputCurrency: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, actions } = props;

  let { options } = props;
  if (!options) options = {};

  if (options.locale === "en-US") {
    if (!("allowNegative" in options)) options.allowNegative = true;
    if (!("fixedDecimalScale" in options)) options.fixedDecimalScale = true;
    if (!options.decimalSeparator) options.decimalSeparator = ".";
    if (!options.decimalScale) options.decimalScale = 2;
    if (!options.prefix) options.prefix = options.currency;
    if (!options.thousandSeparator) options.thousandSeparator = ",";
  } else {
    if (!("allowNegative" in options)) options.allowNegative = true;
    if (!("fixedDecimalScale" in options)) options.fixedDecimalScale = true;
    if (!options.decimalSeparator) options.decimalSeparator = ",";
    if (!options.decimalScale) options.decimalScale = 2;
    if (!options.prefix) options.prefix = options.currency;
    if (!options.thousandSeparator) options.thousandSeparator = ".";
  }

  return (
    <div
      className={`${props.isMissing === true ? `missing` : ``} ${
        value && !isEmail(value) === true ? `warning` : ``
      } ${value && isEmail(value) === true ? `success` : ``} __ptfdn-input-edit`}
    >
      <NumericFormat
        value={value}
        allowNegative={options.allowNegative}
        decimalScale={options.decimalScale}
        decimalSeparator={options.decimalSeparator}
        fixedDecimalScale={options.fixedDecimalScale}
        prefix={options.prefix}
        thousandSeparator={options.thousandSeparator}
        onValueChange={(values) => {
          actions.onEdit(values.floatValue || null);
        }}
      />
    </div>
  );
};

export default InputCurrency;
