import * as React from "react";
import * as FDN from "src/core";
import { IClientSector } from "src/types/clients.types";
import { AdminSectorsActions } from "src/services/admin/useAdminSectors";

interface ITabInformationProps {
  sector: IClientSector;
  missingFields: string[];
  actions: AdminSectorsActions;
}

export default function TabInformation({ sector, missingFields, actions }: ITabInformationProps) {
  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <h3>{FDN.I18n.t("adminSectors.edit.form.groups.information.title")}</h3>
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={sector.code}
                      label={FDN.I18n.t("adminSectors.edit.form.code.label")}
                      placeholder={FDN.I18n.t("adminSectors.edit.form.code.placeholder")}
                      isMissing={missingFields.includes("code")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("code", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={sector.name}
                      label={FDN.I18n.t("adminSectors.edit.form.name.label")}
                      isMissing={missingFields.includes("name")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("name", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>

      {sector.identifier !== "new" && sector.clientCount && sector.clientCount > 0 ? (
        <div className="bg-orange-50 border border-orange-100 rounded-lg px-2 py-1 mb-4 text-orange-500">
          <FDN.Icon icon="info-circle" />
          &nbsp; {FDN.I18n.t("adminSectors.edit.form.deleteNotPossible.label")}
        </div>
      ) : null}

      <FDN.FormButtons
        onCancel={actions.onCancel}
        onSave={actions.onSave}
        onDelete={sector.identifier !== "new" ? actions.onDelete : undefined}
        deleteDisabled={sector.clientCount && sector.clientCount > 0 ? true : false}
      />
    </>
  );
}
