import * as React from "react";
import { IInputEditProps } from "../types";

const defaultRows = 5;

const InputHtmlCode: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, placeholder, rows, actions } = props;

  return (
    <div
      className={`${
        props.isMissing === true ? `missing` : ``
      } __ptfdn-input-edit  __ptfdn-input-htmlcode-edit`}
    >
      <textarea
        value={value || ""}
        rows={rows || defaultRows}
        placeholder={placeholder}
        onChange={(e) => actions.onEdit(e.target.value)}
      />
    </div>
  );
};

export default InputHtmlCode;
