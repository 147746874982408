import * as React from "react";
import { IInputProps } from "../types";
import Icon from "../../Icon";
import { TActions } from "../../../../types/types";

interface IInputCheckbox extends IInputProps {
  actions: TActions;
}

const InputCheckbox: React.FunctionComponent<IInputCheckbox> = (props) => {
  const { value, editMode, label, actions } = props;

  return (
    <label className={`__input-checkbox-label editmode-${editMode === true ? `on` : `off`}`}>
      {editMode === true ? (
        <>
          <input type="checkbox" checked={value || false} onChange={() => actions.onEdit(!value)} />{" "}
          {label}
        </>
      ) : (
        <span>
          <Icon icon={value === true ? `check-square-o` : `square-o`} left /> {label}{" "}
        </span>
      )}
    </label>
  );
};

export default InputCheckbox;
