import * as React from "react";
import * as FDN from "src/core";

interface IFormErrorsProps {
  errors?: { [key: string]: boolean } | null;
}

interface IFormErrorProps {
  errorKey: string;
}

const FormErrors: React.FunctionComponent<IFormErrorsProps> = ({ errors }) => {
  if (!errors) return null;

  return (
    <div className="__formerrors">
      {Object.keys(errors).map((errorKey) => {
        if (errors[errorKey] === true) return <FormError errorKey={errorKey} key={errorKey} />;
        return null;
      })}
    </div>
  );
};

const FormError: React.FunctionComponent<IFormErrorProps> = ({ errorKey }) => {
  return (
    <div className={`__formerror __formerror-${errorKey} small alert callout`}>
      <div className="__formerror-title">{FDN.I18n.t(`errors.${errorKey}.title`)}</div>
      <div className="__formerror-text">{FDN.I18n.t(`errors.${errorKey}.text`)}</div>
    </div>
  );
};

export default FormErrors;
