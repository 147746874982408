import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import { IClientSector } from "src/types/clients.types";
import { AdminSectorsActions } from "src/services/admin/useAdminSectors";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 4 },
    content: FDN.I18n.t("adminSectors.list.head.code.title"),
  },
  {
    size: { md: 16 },
    content: FDN.I18n.t("adminSectors.list.head.name.title"),
  },
  {
    size: {
      md: 4,
    },
    textAlign: "center",
    content: FDN.I18n.t("adminSectors.list.head.clientCount.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (sectors: IClientSector[], actions: AdminSectorsActions) => {
  if (!sectors) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const sector of sectors) {
    if (!sector) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => actions.showPopupEditSector(sector);

    row.columns[0].content = (
      <>
        <div className="__adminlist-name">
          <div className="">{sector.code}</div>
        </div>
      </>
    );

    row.columns[1].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">
            <strong>{sector.name}</strong>
          </div>
        </div>
      </>
    );

    row.columns[2].content = (
      <>
        <div className="flex justify-center items-center">{sector.clientCount ?? 0}</div>
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface SectorsListProps {
  sectors: IClientSector[];
  actions: AdminSectorsActions;
}

const SectorsList: React.FunctionComponent<SectorsListProps> = ({ sectors, actions }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (sectors) {
      setAdminListSchema(buildAdminList(sectors as IClientSector[], actions));
      setListLoading(false);
    }
  }, [sectors]);

  if (!sectors) return <FDN.Loading box />;

  return <>{adminListSchema && <FDN.AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default SectorsList;
