import * as React from "react";
import * as FDN from "src/core";
import { SocketContext } from "src/components/context/SocketContext/SocketContext";
import { Avatar as GlobalAvatar } from "@tschirpke/ptfdn-frontend-components";
import Config from "src/core/Config";

const Avatar: React.FunctionComponent<FDN.IAvatarProps> = (props) => {
  const SOCKETIO = React.useContext(SocketContext);

  return (
    <GlobalAvatar
      {...props}
      hideOnlineStatus={
        props.hideOnlineStatus
          ? props.hideOnlineStatus
          : !(Config.get("privacy.showOnlineStatus") as boolean)
      }
      SOCKETIO={SOCKETIO}
      I18n={FDN.I18n}
    />
  );
};

export default Avatar;
