import * as React from "react";
import Cell from "./Cell";
import Grid from "./Grid";
import Row from "./Row";

interface ISingleRowCellProps {
  full?: boolean;
  fluid?: boolean;
  margin?: string;
  padding?: string;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  gridStyle?: { [key: string]: string | number };
  rowStyle?: { [key: string]: string | number };
  cellStyle?: { [key: string]: string | number };
  gridClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
  children?: any;
}

const SingleRowCell: React.FunctionComponent<ISingleRowCellProps> = (props) => {
  const {
    full,
    fluid,
    margin,
    padding,
    gridStyle,
    rowStyle,
    cellStyle,
    gridClassName,
    rowClassName,
    cellClassName,
    children,
    left,
    center,
    right,
  } = props;

  return (
    <Grid full={full} fluid={fluid} className={gridClassName} style={gridStyle}>
      <Row
        margin={margin}
        padding={padding}
        className={rowClassName}
        style={rowStyle}
      >
        <Cell
          sm={24}
          left={left}
          center={center}
          right={right}
          className={cellClassName}
          style={cellStyle}
        >
          {children}
        </Cell>
      </Row>
    </Grid>
  );
};

export default SingleRowCell;
