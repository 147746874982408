import * as React from "react";
import * as FDN from "src/core";
import { IProductBaseUnit } from "src/types/products.types";
import { AdminBaseUnitsActions } from "src/services/admin/useAdminBaseUnits";

interface ITabInformationProps {
  baseUnit: IProductBaseUnit;
  missingFields: string[];
  actions: AdminBaseUnitsActions;
}

export default function TabInformation({ baseUnit, missingFields, actions }: ITabInformationProps) {
  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <h3>{FDN.I18n.t("adminBaseUnits.edit.form.groups.information.title")}</h3>
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={baseUnit.name}
                      label={FDN.I18n.t("adminBaseUnits.edit.form.name.label")}
                      isMissing={missingFields.includes("name")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("name", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>

      {baseUnit.identifier !== "new" && baseUnit.productCount && baseUnit.productCount > 0 ? (
        <div className="bg-orange-50 border border-orange-100 rounded-lg px-2 py-1 mb-4 text-orange-500">
          <FDN.Icon icon="info-circle" />
          &nbsp; {FDN.I18n.t("adminBaseUnits.edit.form.deleteNotPossible.label")}
        </div>
      ) : null}

      <FDN.FormButtons
        onCancel={actions.onCancel}
        onSave={actions.onSave}
        onDelete={baseUnit.identifier !== "new" ? actions.onDelete : undefined}
        deleteDisabled={baseUnit.productCount && baseUnit.productCount > 0 ? true : false}
      />
    </>
  );
}
