import React, { useState } from "react";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import useServiceCore from "../CoreService";
import { I18n, toggleArrayItem } from "src/core";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import {
  IClient,
  IClientAddUser,
  IClientCategorySetting,
  IClientCategorySettingOptions,
  IClients,
  IClientSector,
} from "src/types/clients.types";
import { ApiResponse } from "src/api/Api";
import { IProductCategories, IProductCategory } from "src/types/products.types";

export interface TActionsAdminClients {
  init: () => void;
  showPopupEdit: (product: IClient | "new") => void;
  hidePopupEdit: () => void;
  onEdit: <P extends keyof IClient, V extends IClient[P]>(property: P, value: V) => void;
  onSave: () => void;
  userCheckEmail: (email: string) => Promise<ApiResponse | null | undefined>;
  userAddUser: (user: IClientAddUser, type: string) => Promise<boolean> | undefined;
  upgradeToManager: (userIdentifier: string) => Promise<boolean> | undefined;
  downgradeToEmployee: (userIdentifier: string) => Promise<boolean> | undefined;
  userRemoveUser: (userIdentifier: string) => Promise<boolean> | undefined;
  toggleClientProductCategory: (categoryIdentifier: string) => void;
  isCategoryEnabled: (categoryIdentifier: string) => boolean;
  getCategorySettingsOption: (categoryIdentifier: string) => IClientCategorySettingOptions;
  getSelectedProductsCount: (categoryIdentifier: string) => number;
  selectCategory: (category: IProductCategory | undefined) => void;
  setCategorySettingOption: (categoryIdentifier: string, option: IClientCategorySettingOptions) => void;
  toggleEnabledProduct: (productIdentifier: string) => void;
  copyProductsFromClient: (clientIdentifier: string) => void;
}

const useServiceAdminClients = () => {
  const [clients, setClients] = useState<IClients>();

  const [virginClient, setVirginClient] = useState<IClient>();
  const [editClient, setEditClient] = useState<IClient>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const [categories, setCategories] = useState<IProductCategories>();
  const [selectedCategory, setSelectedCategory] = useState<IProductCategory>();

  const [sectors, setSectors] = useState<IClientSector[]>();
  const [originalSectorIdentifier, setOriginalSectorIdentifier] = useState<string>();

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const init = () => {
    AdminApi.getClients(api).then((response) => {
      APP.setPageTitle(I18n.t("adminClients.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setClients(response?.body?.clients as IClients);
        setVirginClient(response?.body?.virginClient as IClient);
        setCategories(response?.body?.categories as IProductCategories);
        setSectors(response?.body?.sectors as IClientSector[]);
      }
    });
  };

  const showPopupEdit = (client: IClient | "new") => {
    if (client === "new") {
      if (virginClient) {
        const newClient = cloneDeep(virginClient);
        setEditClient(newClient);
      }
    } else setEditClient(cloneDeep(client));
  };

  const hidePopupEdit = () => {
    const doCancel = () => {
      setEditClient(undefined);
      setOriginalSectorIdentifier(undefined);
      setUnsavedChanges(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  function onEdit<P extends keyof IClient, V extends IClient[P]>(property: P, value: V) {
    if (!editClient) return;

    if (property === "sectorIdentifier" && editClient.sectorIdentifier !== value && !originalSectorIdentifier)
      setOriginalSectorIdentifier(editClient.sectorIdentifier ?? "WAS_EMPTY");

    editClient[property] = value;
    setEditClient(cloneDeep(editClient));
    setUnsavedChanges(true);
  }

  const onSave = () => {
    if (!editClient) return;

    const missingFields: string[] = [];
    if (!editClient.name) missingFields.push("name");
    if (!editClient.sectorIdentifier) missingFields.push("sectorIdentifier");

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      return;
    }

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveClient(api, editClient.identifier, editClient).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminClients.popup.client.onSave.success.title"),
          I18n.t("adminClients.popup.client.onSave.success.text")
        );
        setClients(response?.body?.clients as IClients);
        setEditClient(undefined);
        setOriginalSectorIdentifier(undefined);
        setUnsavedChanges(false);
      }
    });
    NOTIFICATIONS.hideSaving();
  };

  const userCheckEmail = (email: string): Promise<ApiResponse | null | undefined> => {
    return AdminApi.clientCheckEmail(api, email);
  };

  const userAddUser = (user: IClientAddUser, type: string): Promise<boolean> | undefined => {
    if (!editClient || editClient.identifier === "new") return;

    NOTIFICATIONS.showSaving({ type: "save" });

    return AdminApi.clientAddUserAccount(api, editClient.identifier, user, type).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const clients = response?.body?.clients as IClients;
        setClients(clients);

        const updatedEditClient = clients.find((c) => c.identifier === editClient.identifier);
        if (updatedEditClient) setEditClient(cloneDeep(updatedEditClient));

        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminClients.users.add.form.onSave.success.title"),
          I18n.t("adminClients.users.add.form.onSave.success.text")
        );
        NOTIFICATIONS.hideSaving();
        return true;
      } else {
        NOTIFICATIONS.hideSaving();
        return false;
      }
    });
  };

  const upgradeToManager = (userIdentifier: string): Promise<boolean> | undefined => {
    if (!editClient || editClient.identifier === "new") return;

    if (window.confirm(I18n.t("adminClients.users.add.form.onUpgradeToManager.label"))) {
      NOTIFICATIONS.showSaving({ type: "save" });

      return AdminApi.clientSwitchToManager(api, editClient.identifier, userIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const clients = response?.body?.clients as IClients;
          setClients(clients);

          const updatedEditClient = clients.find((c) => c.identifier === editClient.identifier);
          if (updatedEditClient) setEditClient(cloneDeep(updatedEditClient));

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminClients.users.add.form.onUpgradeToManager.success.title"),
            I18n.t("adminClients.users.add.form.onUpgradeToManager.success.text")
          );
          NOTIFICATIONS.hideSaving();
          return true;
        } else {
          NOTIFICATIONS.hideSaving();
          return false;
        }
      });
    }
  };

  const downgradeToEmployee = (userIdentifier: string): Promise<boolean> | undefined => {
    if (!editClient || editClient.identifier === "new") return;

    if (window.confirm(I18n.t("adminClients.users.add.form.onDowngradeToEmployee.label"))) {
      NOTIFICATIONS.showSaving({ type: "save" });

      return AdminApi.clientSwitchToEmployee(api, editClient.identifier, userIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const clients = response?.body?.clients as IClients;
          setClients(clients);

          const updatedEditClient = clients.find((c) => c.identifier === editClient.identifier);
          if (updatedEditClient) setEditClient(cloneDeep(updatedEditClient));

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminClients.users.add.form.onDowngradeToEmployee.success.title"),
            I18n.t("adminClients.users.add.form.onDowngradeToEmployee.success.text")
          );
          NOTIFICATIONS.hideSaving();
          return true;
        } else {
          NOTIFICATIONS.hideSaving();
          return false;
        }
      });
    }
  };

  const userRemoveUser = (userIdentifier: string): Promise<boolean> | undefined => {
    if (!editClient || editClient.identifier === "new") return;

    if (window.confirm(I18n.t("adminClients.users.add.form.onConfirm.label"))) {
      NOTIFICATIONS.showSaving({ type: "save" });

      return AdminApi.clientRemoveUserAccount(api, editClient.identifier, userIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const clients = response?.body?.clients as IClients;
          setClients(clients);

          const updatedEditClient = clients.find((c) => c.identifier === editClient.identifier);
          if (updatedEditClient) setEditClient(cloneDeep(updatedEditClient));

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminClients.users.add.form.onRemove.success.title"),
            I18n.t("adminClients.users.add.form.onRemove.success.text")
          );
          NOTIFICATIONS.hideSaving();
          return true;
        } else {
          NOTIFICATIONS.hideSaving();
          return false;
        }
      });
    }
  };

  const toggleClientProductCategory = (categoryIdentifier: string) => {
    if (!editClient) return;

    if (!editClient.categorySettings) editClient.categorySettings = [];

    if (isCategoryEnabled(categoryIdentifier) === true) {
      const updatedCategorySettings: IClientCategorySetting[] = [];
      for (const cs of editClient.categorySettings) {
        if (cs.categoryIdentifier !== categoryIdentifier) updatedCategorySettings.push(cs);
      }
      editClient.categorySettings = updatedCategorySettings;
      setEditClient(cloneDeep(editClient));
      setSelectedCategory(undefined);
    } else {
      const newCategorySetting: IClientCategorySetting = {
        categoryIdentifier: categoryIdentifier,
        option: "all",
      };
      editClient.categorySettings.push(newCategorySetting);
      setEditClient(cloneDeep(editClient));

      const category = categories?.find((c) => c.identifier === categoryIdentifier);
      if (category) setSelectedCategory(category);
    }
  };

  const isCategoryEnabled = (categoryIdentifier: string): boolean => {
    if (!editClient) return false;

    const categorySetting = editClient.categorySettings?.find((cs) => cs.categoryIdentifier === categoryIdentifier);

    if (categorySetting && (categorySetting.option === "all" || categorySetting.option === "selected")) return true;

    return false;
  };

  const getCategorySettingsOption = (categoryIdentifier: string): IClientCategorySettingOptions => {
    if (!editClient) return "none";

    const categorySetting = editClient.categorySettings?.find((cs) => cs.categoryIdentifier === categoryIdentifier);

    if (categorySetting) return categorySetting.option;
    else return "none";
  };

  const getSelectedProductsCount = (categoryIdentifier: string): number => {
    if (!editClient) return 0;

    const categorySetting = editClient.categorySettings?.find((cs) => cs.categoryIdentifier === categoryIdentifier);

    if (categorySetting) {
      const category = categories?.find((c) => c.identifier === categoryIdentifier);

      if (!category) return 0;
      else {
        if (categorySetting.option === "all") return category.products?.length || 0;

        let count = 0;

        for (const product of category.products ?? []) {
          if ((editClient.enabledProducts ?? []).includes(product.identifier)) count++;
        }

        return count;
      }
    }

    return 0;
  };

  // const getSelectedProductsCount = (categoryIdentifier: string): number => {
  //   if (!editClient) return 0;

  //   const categorySetting = editClient.categorySettings?.find((cs) => cs.categoryIdentifier === categoryIdentifier);

  //   if (categorySetting) {
  //     const category = categories?.find((c) => c.identifier === categoryIdentifier);

  //     if (!category) return 0;
  //     else return category.products?.length || 0;
  //   }

  //   return 0;
  // };

  const selectCategory = (category: IProductCategory | undefined) => {
    if (!category) setSelectedCategory(undefined);
    else setSelectedCategory(cloneDeep(category));
  };

  const setCategorySettingOption = (categoryIdentifier: string, option: IClientCategorySettingOptions) => {
    if (!editClient) return 0;

    const categorySetting = editClient.categorySettings?.find((cs) => cs.categoryIdentifier === categoryIdentifier);

    if (categorySetting) {
      categorySetting.option = option;
      setEditClient(cloneDeep(editClient));
    }
  };

  const toggleEnabledProduct = (productIdentifier: string) => {
    if (!editClient) return;

    if (!editClient.enabledProducts) editClient.enabledProducts = [];

    editClient.enabledProducts = toggleArrayItem(productIdentifier, editClient.enabledProducts);
    setEditClient(cloneDeep(editClient));
  };

  const copyProductsFromClient = (clientIdentifier: string) => {
    if (!clients || !editClient) return;
    const clientFrom = clients.find((c) => c.identifier === clientIdentifier);
    if (!clientFrom) return;

    const doCopy = () => {
      editClient.categorySettings = cloneDeep(clientFrom.categorySettings);
      editClient.enabledProducts = cloneDeep(clientFrom.enabledProducts);
      setEditClient(cloneDeep(editClient));
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminClients.popup.products.category.copy.confirm", {
        clientFrom: clientFrom.name,
        clientTo: editClient.name,
      }),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doCopy(),
        },
      ],
    });
  };

  const actions: TActionsAdminClients = {
    init,
    showPopupEdit,
    hidePopupEdit,
    onEdit,
    onSave,
    userCheckEmail,
    userAddUser,
    upgradeToManager,
    downgradeToEmployee,
    userRemoveUser,
    toggleClientProductCategory,
    getCategorySettingsOption,
    isCategoryEnabled,
    getSelectedProductsCount,
    selectCategory,
    setCategorySettingOption,
    toggleEnabledProduct,
    copyProductsFromClient,
  };

  return {
    clients,
    editClient,
    missingFields,
    categories,
    selectedCategory,
    sectors,
    originalSectorIdentifier,
    actions,
    APP,
  };
};

export default useServiceAdminClients;
