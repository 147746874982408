import * as React from "react";

interface ICellProps {
  sm?: number;
  md?: number;
  lg?: number;
  smo?: number;
  mdo?: number;
  lgo?: number;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  style?: { [key: string]: string | number };
  className?: string;
  children?: any;
}

const Cell: React.FunctionComponent<ICellProps> = (props) => {
  let { sm, md, lg, smo, mdo, lgo } = props;
  const { left, center, right, style, className } = props;

  const defaultValue = 24;
  const defaultOffsetValue = 0;

  if (!sm) sm = defaultValue;
  if (!md) md = sm;
  if (!lg) lg = md;

  if (!smo) smo = defaultOffsetValue;
  if (!mdo) mdo = smo;
  if (!lgo) lgo = mdo;

  const getClasses = () => {
    let classes = `cell small-${sm} medium-${md} large-${lg} small-offset-${smo} medium-offset-${mdo} large-offset-${lgo}`;

    if (left) classes += " text-left";
    if (center) classes += " text-center";
    if (right) classes += " text-right";

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
};

export default Cell;
