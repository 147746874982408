import React from "react";
import SingleFileUploader from "src/components/common/uploaders/components/SingleFileUploader";
import * as FDN from "src/core";
import { TActions } from "src/types/types";

interface MultiUploadProps {
  actions: TActions;
  view: "upload" | "error";
}

export default function MultiUpload({ actions, view }: MultiUploadProps) {
  return (
    <div>
      <h3>{FDN.I18n.t("pr.freeitems.csv.view.upload.title")}</h3>
      <div>{FDN.I18n.t("pr.freeitems.csv.view.upload.intro")}</div>

      <div className="flex justify-center items-center py-4">
        <a
          href="/downloads/Artikel_Upload_Template.csv"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-primary hover:font-semibold"
        >
          {FDN.I18n.t("pr.freeitems.csv.buttons.download.label")}
        </a>
      </div>

      {view === "error" && (
        <FDN.CalloutBox type="alert">
          <div className="font-semibold">{FDN.I18n.t("pr.freeitems.csv.error.title")}</div>
          <div>{FDN.I18n.t("pr.freeitems.csv.error.text")}</div>
        </FDN.CalloutBox>
      )}

      <div className="pt-4">
        <div className="pb-2 pt-4 text-primary text-lg">
          <strong>{FDN.I18n.t("pr.freeitems.csv.upload.title")}</strong>
        </div>
        <SingleFileUploader
          acceptedFileTypes={{ csv: [".csv"] }}
          uploadType="freeitems"
          onUpdate={(_, file) => {
            if (file?.identifier) {
              actions.onUploadFinished(file.identifier);
            }
          }}
        />
      </div>
    </div>
  );
}
