import Config from "../Config";
import { I18nCore } from "@tschirpke/ptfdn-frontend-components";

import main from "./translations/main.json";
import buttons from "./translations/buttons.json";
import errors from "./translations/errors.json";
import topbar from "./translations/topbar.json";

import login from "./translations/pages/login.json";
import passwordreset from "./translations/pages/passwordreset.json";
import signup from "./translations/pages/signup.json";
import welcome from "./translations/pages/welcome.json";
import myaccount from "./translations/pages/myaccount.json";
import usersettings from "./translations/pages/usersettings.json";

import imageupload from "./translations/components/imageupload.json";
import passwordsetform from "./translations/components/passwordsetform.json";
import cookiebanner from "./translations/components/cookiebanner.json";
import languageswitch from "./translations/components/languageswitch.json";
import usernotifications from "./translations/components/usernotifications.json";

import pr from "./translations/pr.json";

import adminAdmin from "./translations/admin/admin.json";
import adminLogs from "./translations/admin/logs.json";
import adminDashboard from "./translations/admin/dashboard.json";
import adminAccounts from "./translations/admin/accounts.json";
import adminUserroles from "./translations/admin/userroles.json";
import adminSettings from "./translations/admin/settings.json";
import adminEmailTemplates from "./translations/admin/emailtemplates.json";

import adminProducts from "./translations/admin/products.json";
import adminBaseUnits from "./translations/admin/baseunits.json";
import adminSuppliers from "./translations/admin/suppliers.json";
import adminClients from "./translations/admin/clients.json";
import adminPr from "./translations/admin/pr.json";
import adminStaff from "./translations/admin/staff.json";
import adminSectors from "./translations/admin/sectors.json";

const translations: { [key: string]: any } = {
  main,
  buttons,
  errors,
  topbar,

  login,
  passwordreset,
  signup,
  welcome,
  myaccount,
  usersettings,

  imageupload,
  passwordsetform,
  cookiebanner,
  languageswitch,
  usernotifications,

  pr,

  adminAdmin,
  adminLogs,
  adminDashboard,
  adminAccounts,
  adminUserroles,
  adminSettings,
  adminEmailTemplates,

  adminProducts,
  adminBaseUnits,
  adminSuppliers,
  adminClients,
  adminPr,
  adminStaff,
  adminSectors,
};

const I18n = new I18nCore(Config.get("i18n.defaultLanguage") as string, translations);

export default I18n;
