import * as React from "react";
import * as FDN from "src/core";
import { formatDateTime } from "src/core/helpers/helpers";
import StatusCode from "src/config/statuscodes";
import { IEditLockStatus } from "src/components/context/NotificationsContext/types";

interface IEditLockMessageProps {
  editLockStatus: IEditLockStatus | null;
}

const EditLockMessage: React.FunctionComponent<IEditLockMessageProps> = ({ editLockStatus }) => {
  if (!editLockStatus || editLockStatus.status === StatusCode.EDITLOCK_OWN) return null;

  return (
    <div className="__admin-editlock">
      <FDN.CalloutBox type="warning">
        <div>
          <strong>{FDN.I18n.t("adminAdmin.editLock.title")}</strong>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: FDN.I18n.t("adminAdmin.editLock.text", {
              userName: `<em>${editLockStatus.userName}</em>`,
            }),
          }}
        />
        <div>
          lastcheck:
          {editLockStatus.lastCheck && formatDateTime(editLockStatus.lastCheck, { seconds: true })}
        </div>
      </FDN.CalloutBox>
    </div>
  );
};

export default EditLockMessage;
