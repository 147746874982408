import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { IClients, IClientSector } from "src/types/clients.types";
import { classNames } from "src/core/helpers/design.helper";

interface ITabClientsProps {
  sector: IClientSector;
}

export default function TabClients({ sector }: ITabClientsProps) {
  const [clients, setClients] = React.useState<IClients | undefined>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    AdminApi.getSectorClients(api, sector.identifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setClients(response?.body?.clients as IClients);
      }
    });
  }, []);

  if (!clients)
    return (
      <div className="py-12">
        <FDN.Loading box />
      </div>
    );

  return (
    <div>
      <div className="text-sm text-gray-500 mb-2">{FDN.I18n.t("adminSectors.edit.products.intro")}</div>
      {clients.map((client, index) => (
        <div key={client.identifier}>
          <div className={classNames("px-4 py-1 flex justify-start gap-2", index !== 0 && "border-t border-gray-200")}>
            <div className="w-6 flex-shrink-0">#{index + 1}</div>
            <div className="font-medium text-gray-500 flex-shrink-0 w-36">{client.clientNr}</div>
            <div className="font-semibold flex-grow">{client.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
