import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import { userHasPermission } from "src/core/AdminService/helpers";
import useAdminBaseUnits from "src/services/admin/useAdminBaseUnits";
import BaseUnitsList from "./parts/BaseUnitsList";
import PopupBaseUnitEdit from "./parts/PopupBaseUnitEdit";

const AdminBaseUnits: React.FunctionComponent = () => {
  const { baseUnits, selectedBaseUnit, missingFields, actions, APP } = useAdminBaseUnits();
  const user = APP.getUser();

  if (!baseUnits) return <FDN.Loading box />;

  return (
    <div className="__admin-base-units">
      <AdminLayout page="admin-base-units" selectedNav="baseunits">
        <AdminHeader
          title={FDN.I18n.t("adminBaseUnits.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button className="primary button" onClick={() => actions.showPopupEditBaseUnit("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminBaseUnits.buttons.add.label")}
                </button>
              )}
            </>,
          ]}
        />

        <div className="__box-padding">
          <BaseUnitsList baseUnits={baseUnits} actions={actions} />
        </div>

        {selectedBaseUnit && (
          <PopupBaseUnitEdit baseUnit={selectedBaseUnit} missingFields={missingFields} actions={actions} />
        )}
      </AdminLayout>
    </div>
  );
};

export default AdminBaseUnits;
