import * as React from "react";
import Icon from "../Icon";

interface IPopupProps {
  size?: "tiny" | "small" | "medium" | "large" | "fullwidth" | "fullscreen";
  hide?: boolean;
  hideCloseButton?: boolean;
  noPadding?: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
}

const Popup: React.FunctionComponent<IPopupProps> = ({
  size,
  hide,
  hideCloseButton,
  noPadding,
  title,
  children,
  onClose,
}) => {
  if (!size) size = "medium";
  if (!hide) hide = false;

  return (
    <div className="__popup">
      <div
        className="__popup-modal"
        onClick={() => {
          if (hideCloseButton !== true && onClose) onClose();
        }}
      >
        <div className={`__popup-popup ${size}`} onClick={(e) => e.stopPropagation()}>
          {title || !hideCloseButton ? (
            <div className="__popup-header">
              <h2 className="__popup-title">{title}</h2>
              {hideCloseButton !== true && onClose ? (
                <div className="__popup-close-button-wrapper">
                  <button className="__popup-close-button" onClick={() => onClose()}>
                    <Icon icon="gg-close" />
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className={`__popup-popup-content ${noPadding === true && `nopadding`}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
