import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useAdminStaffs from "src/services/admin/useAdminStaffs";
import StaffsList from "./parts/StaffsList";
import PopupStaffEdit from "./parts/PopupStaffEdit";

const AdminStaffs: React.FunctionComponent = () => {
  const { staffs, selectedStaff, clients, actions, APP } = useAdminStaffs();

  if (!staffs) return <FDN.Loading box />;

  return (
    <div className="__admin-staffs">
      <AdminLayout page="admin-staff" selectedNav="staff">
        <AdminHeader title={FDN.I18n.t("adminStaff.header.title")} />

        <div className="__box-padding">
          <StaffsList staffs={staffs} actions={actions} />
        </div>

        {selectedStaff && <PopupStaffEdit staff={selectedStaff} clients={clients} actions={actions} />}
      </AdminLayout>
    </div>
  );
};

export default AdminStaffs;
