import React from "react";
import * as FDN from "src/core";
import { userHasPermission } from "src/core/AdminService/helpers";
import { classNames } from "src/core/helpers/design.helper";
import useServiceCore from "src/services/CoreService";
import { IClient } from "src/types/clients.types";
import { IAccountState, TActions } from "src/types/types";

interface IPopupStaffEdit {
  staff: IAccountState;
  clients?: IClient[];
  actions: TActions;
}

export default function PopupStaffEdit({ staff, clients, actions }: IPopupStaffEdit) {
  const { APP } = useServiceCore();
  const user = APP.getUser();

  const editMode = userHasPermission(user, "adminStaffEdit");

  const selectValuesType = {
    manager: FDN.I18n.t("adminStaff.edit.form.type.manager.label"),
    employee: FDN.I18n.t("adminStaff.edit.form.type.employee.label"),
  };

  return (
    <div className="admin-edit-staff">
      <FDN.Popup size="small" title={staff.displayname} onClose={actions.onCancel}>
        {user?.identifier === staff.identifier && (
          <FDN.CalloutBox type="warning">
            <div className="font-semibold">{FDN.I18n.t("adminStaff.edit.form.editingSelf.title")}</div>
            <div>{FDN.I18n.t("adminStaff.edit.form.editingSelf.text")}</div>
          </FDN.CalloutBox>
        )}
        <div className="pt-2 pb-2">
          <FDN.Input
            type="select"
            editMode={editMode}
            value={staff.roles.includes("SMAPLY_MANAGER") ? "manager" : "employee"}
            selectValues={selectValuesType}
            onUpdate={(value) => actions.onEditType(value as "manager" | "employee")}
          />
        </div>

        {staff.roles.includes("SMAPLY_MANAGER") ? (
          <div className="py-4 text-center italic">{FDN.I18n.t("adminStaff.edit.form.clients.isManager.label")}</div>
        ) : (
          <div className="">
            <h3 className="mt-4 mb-2">{FDN.I18n.t("adminStaff.edit.form.clients.title")}</h3>

            <div className="">
              <ul>
                {clients?.map((client) => {
                  return (
                    <li key={client.identifier}>
                      <label
                        className={classNames(
                          "flex flex-row gap-4 px-2 py-0.5",
                          editMode && "cursor-pointer hover:bg-primaryHover"
                        )}
                      >
                        <div className="">
                          {editMode ? (
                            <input
                              type="checkbox"
                              checked={staff.staffAccessToClients?.includes(client.identifier)}
                              onChange={() => actions.onToggleClient(client.identifier)}
                            />
                          ) : (
                            <FDN.Icon icon="check" />
                          )}
                        </div>

                        <div className="">{client.clientNr}</div>
                        <div className="flex-grow">
                          <span className="font-semibold">{client.name}</span>
                          {client.zip || client.city ? "," : ""} {client.zip} {client.city}
                        </div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        {editMode && (
          <div className="pt-4">
            <FDN.FormButtons smallButtons onCancel={actions.onCancel} onSave={actions.onSave} />
          </div>
        )}
      </FDN.Popup>
    </div>
  );
}
