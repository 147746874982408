import { FileUploadFile } from "src/components/main/ImageUpload/types";
import Api from "./Api";

export default class FilesApi {
  public static uploadFiles(api: Api, type: string, files: FileUploadFile[]) {
    return api.uploadFiles("files.upload", { type }, files);
  }

  public static uploadFile(api: Api, type: string, file: FileUploadFile) {
    return api.uploadFiles("files.upload", { type }, [file]);
  }
}
