import React from "react";
import * as FDN from "src/core";

interface IPagination {
  position: "top" | "bottom";
  page: number;
  pagesTotal: number;
  selectPage: (page: number) => void;
}

const Pagination: React.FunctionComponent<IPagination> = ({
  position,
  page,
  pagesTotal,
  selectPage,
}) => {
  if (pagesTotal <= 1) return null;

  return (
    <div className={`__adminlist-body-pagination __adminlist-body-pagination-position-${position}`}>
      <button
        onClick={() => selectPage(1)}
        className={`${page === 1 && `disabled`} __adminlist-body-pagination-page`}
        disabled={page === 1}
      >
        <FDN.Icon icon="angle-double-left" />
      </button>
      <button
        onClick={() => selectPage(page - 1)}
        className={`${page === 1 && `disabled`} __adminlist-body-pagination-page`}
        disabled={page === 1}
      >
        <FDN.Icon icon="angle-left" />
      </button>
      {new Array(pagesTotal).fill(undefined).map((content, index) => {
        const pageNumber = index + 1;
        return (
          <button
            onClick={() => selectPage(pageNumber)}
            key={pageNumber}
            className={`${page === pageNumber && `selected`} __adminlist-body-pagination-page`}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        onClick={() => selectPage(page + 1)}
        className={`${page === pagesTotal && `disabled`} __adminlist-body-pagination-page`}
        disabled={page === pagesTotal}
      >
        <FDN.Icon icon="angle-right" />
      </button>
      <button
        onClick={() => selectPage(pagesTotal)}
        className={`${page === pagesTotal && `disabled`} __adminlist-body-pagination-page`}
        disabled={page === pagesTotal}
      >
        <FDN.Icon icon="angle-double-right" />
      </button>
    </div>
  );
};

export default Pagination;
