import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useServiceAdminEmailTemplates from "src/services/admin/EmailTemplatesService";
import EmailTemplatesList from "./parts/EmailTemplatesList";

const AdminEmailTemplates: React.FunctionComponent = () => {
  const { actions, emailTemplates } = useServiceAdminEmailTemplates();

  if (!actions || !emailTemplates) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-emailtemplates" selectedNav="emailtemplates">
      <AdminHeader title={FDN.I18n.t("adminEmailTemplates.header.title")} />
      <EmailTemplatesList emailTemplates={emailTemplates} actions={actions} />
    </AdminLayout>
  );
};

export default AdminEmailTemplates;
