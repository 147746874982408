import React, { useEffect, useState } from "react";
import {
  IProduct,
  IProductBaseUnit,
  IProductCategories,
  IProductCategory,
  IProductUnits,
  IProducts,
  ISuppliers,
} from "../../types/products.types";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import useServiceCore from "../CoreService";
import { I18n } from "src/core";
import { cloneDeep } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import UrlService from "src/core/UrlService";
import { PrFormSearchProps } from "src/types/pr.types";

const initSearch: PrFormSearchProps = {
  text: "",
  supplier: "",
  category: "",
  sortBy: "productCategory_asc",
};

export interface TActionsAdminProducts {
  selectCategory: (categoryIdentifier: string | undefined) => void;
  getFilteredProducts: (categoryIdentifier?: string) => IProducts | false | undefined;
  showPopupEditCategory: (category: IProductCategory | "new") => void;
  hidePopupEditCategory: () => void;
  onEdiCategory: <P extends keyof IProductCategory, V extends IProductCategory[P]>(property: P, value: V) => void;
  onDeleteCategory: () => void;
  onSaveCategory: () => void;
  showPopupEditProduct: (product: IProduct | "new") => void;
  hidePopupEditProduct: () => void;
  onEditProduct: <P extends keyof IProduct, V extends IProduct[P]>(property: P, value: V) => void;
  onDeleteProduct: () => void;
  onSaveProduct: () => void;
  updateSearch: <P extends keyof PrFormSearchProps, V extends PrFormSearchProps[P]>(property: P, value: V) => void;
  searchInProducts: (tmpProducts: IProducts) => void;
  resetSearch: () => void;
}

const useServiceAdminProducts = () => {
  const [categories, setCategories] = useState<IProductCategories>();
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const [productUnits, setProductUnits] = useState<IProductUnits>();
  const [suppliers, setSuppliers] = useState<ISuppliers>();
  const [baseUnits, setBaseUnits] = useState<IProductBaseUnit[]>();

  const [virginCategory, setVirginCategory] = useState<IProductCategory>();
  const [editCategory, setEditCategory] = useState<IProductCategory>();
  const [unsavedChangesCategory, setUnsavedChangesCategory] = useState(false);

  const [virginProduct, setVirginProduct] = useState<IProduct>();
  const [editProduct, setEditProduct] = useState<IProduct>();
  const [unsavedChangesProduct, setUnsavedChangesProduct] = useState(false);
  const [missingFieldsProduct, setMissingFieldsProduct] = useState<string[]>([]);

  const [search, setSearch] = useState<PrFormSearchProps>(cloneDeep(initSearch));

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const navigate = useNavigate();

  const params = useParams();
  const categoryIdentifier = params.categoryIdentifier;

  React.useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!categories) return;

    if (categoryIdentifier && selectedCategory !== categoryIdentifier) setSelectedCategory(categoryIdentifier);
  }, [categories]);

  const init = () => {
    AdminApi.getProductCategories(api).then((response) => {
      APP.setPageTitle(I18n.t("adminProducts.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setCategories(response?.body?.categories as IProductCategories);
        setVirginCategory(response?.body?.virginCategory as IProductCategory);
        setVirginProduct(response?.body?.virginProduct as IProduct);
        setProductUnits(response?.body?.productUnits as IProductUnits);
        setSuppliers(response?.body?.suppliers as ISuppliers);
        setBaseUnits(response?.body?.baseUnits as IProductBaseUnit[]);
      }
    });
  };

  const selectCategory = (categoryIdentifier: string | undefined) => {
    setSelectedCategory(categoryIdentifier);
    if (categoryIdentifier) navigate(UrlService.url("admin.products.category", { categoryIdentifier }));
    else navigate(UrlService.url("admin.products.index"));
  };

  const getFilteredProducts = (categoryIdentifier?: string): IProducts | false | undefined => {
    if (!categoryIdentifier) categoryIdentifier = selectedCategory;
    if (!selectedCategory) {
      if (!categories) return false;

      let allProducts: IProducts = [];
      for (const category of categories) {
        if (category.products) allProducts = [...allProducts, ...category.products];
      }
      if (!search) return allProducts;
      return searchInProducts(allProducts);
    }

    const category = categories?.find((c) => c.identifier === categoryIdentifier);
    if (!category || !category.products) return false;

    if (!search) return category.products;

    return searchInProducts(category.products);
  };

  const searchInProducts = (tmpProducts: IProducts) => {
    if (!search) return tmpProducts;

    const products: IProducts = [];

    for (const product of tmpProducts) {
      let addToList = true;

      if (search.text) {
        const searchText = search.text.toLowerCase();

        if (!product.name?.toLowerCase().includes(searchText) && !product.productId?.toLowerCase().includes(searchText))
          addToList = false;
      }

      if (addToList && search.category) {
        if (product.categoryIdentifier !== search.category) addToList = false;
      }

      if (addToList === true) products.push(product);
    }

    if (search.sortBy) return sortProducts(products, search.sortBy);
  };

  const sortProducts = (products: IProducts, sortBy: string): IProducts => {
    const [sortByKey, sortByDirection] = sortBy.split("_");

    let property = "";
    if (sortByKey === "unit") property = "unit";
    else if (sortByKey === "productName") property = "name";
    else if (sortByKey === "productCategory") property = "categoryName";
    else if (sortByKey === "productId") property = "productId";
    else if (sortByKey === "priceUnit") property = "priceUnit";

    if (!property || (sortByDirection !== "asc" && sortByDirection !== "desc")) return products;

    const sorted = products.sort((a, b) => {
      if (property === "unit") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "name") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "categoryName") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "productId") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "priceUnit") {
        if (sortByDirection === "asc") return (a[property] || 0) - (b[property] || 0);
        else return (b[property] || 0) - (a[property] || 0);
      } else return 1;
    });

    return sorted;
  };

  const showPopupEditCategory = (category: IProductCategory | "new") => {
    if (category === "new") setEditCategory(cloneDeep(virginCategory));
    else setEditCategory(cloneDeep(category));
  };

  const hidePopupEditCategory = () => {
    const doCancel = () => {
      setEditCategory(undefined);
      setUnsavedChangesCategory(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChangesCategory)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  function onEdiCategory<P extends keyof IProductCategory, V extends IProductCategory[P]>(property: P, value: V) {
    if (!editCategory) return;

    editCategory[property] = value;
    setEditCategory(cloneDeep(editCategory));
    setUnsavedChangesCategory(true);
  }

  const onSaveCategory = () => {
    if (!editCategory) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveProductCategory(api, editCategory.identifier, editCategory).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminProducts.popup.category.onSave.success.title"),
          I18n.t("adminProducts.popup.category.onSave.success.text")
        );
        setCategories(response?.body?.categories as IProductCategories);
        setEditCategory(undefined);
      }
    });
    NOTIFICATIONS.hideSaving();
  };
  const onDeleteCategory = () => {
    if (!editCategory) return;

    const doDelete = () => {
      NOTIFICATIONS.showSaving({ type: "delete" });

      AdminApi.deleteProductCategory(api, editCategory.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminProducts.popup.category.onDelete.success.title"),
            I18n.t("adminProducts.popup.category.onDelete.success.text")
          );
          setCategories(response?.body?.categories as IProductCategories);
          setEditCategory(undefined);
          window.location.href = UrlService.url("admin.products.index");
        }
      });
      NOTIFICATIONS.hideSaving();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminProducts.popup.category.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doDelete(),
        },
      ],
    });
  };

  const showPopupEditProduct = (product: IProduct | "new") => {
    if (product === "new") {
      if (virginProduct) {
        const newProduct = cloneDeep(virginProduct);
        if (selectedCategory) newProduct.categoryIdentifier = selectedCategory;
        setEditProduct(newProduct);
      }
    } else setEditProduct(cloneDeep(product));
  };

  const hidePopupEditProduct = () => {
    const doCancel = () => {
      setEditProduct(undefined);
      setUnsavedChangesProduct(false);
      NOTIFICATIONS.hideDialog();

      // Remove product query param from URL if it exists
      const url = new URL(window.location.href);
      if (url.searchParams.has("product")) {
        url.searchParams.delete("product");
        window.history.replaceState({}, "", url.toString());
      }
    };

    if (unsavedChangesProduct)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  function onEditProduct<P extends keyof IProduct, V extends IProduct[P]>(property: P, value: V) {
    if (!editProduct) return;

    if (property === "supplierIdentifiers") {
      if (value === "none") editProduct.supplierIdentifiers = [];
      else editProduct.supplierIdentifiers = [value as string];
    } else editProduct[property] = value;

    setEditProduct(cloneDeep(editProduct));
    setUnsavedChangesProduct(true);
  }

  const onDeleteProduct = () => {
    if (!editProduct) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminProducts.popup.product.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.showSaving({ type: "save" });

            AdminApi.archiveProduct(api, editProduct.identifier).then((response) => {
              if (response?.statusCode === StatusCode.SUCCESS) {
                NOTIFICATIONS.showNotification(
                  "success",
                  I18n.t("adminProducts.popup.product.onDelete.success.title"),
                  I18n.t("adminProducts.popup.product.onDelete.success.text")
                );
                setCategories(response?.body?.categories as IProductCategories);
                setEditProduct(undefined);
                setUnsavedChangesProduct(false);
              }
            });
            NOTIFICATIONS.hideSaving();
          },
        },
      ],
    });
  };

  const onSaveProduct = () => {
    console.log("1");
    if (!editProduct) return;

    console.log("2");

    const missingFields: string[] = [];
    if (!editProduct.name) missingFields.push("name");
    if (!editProduct.categoryIdentifier || editProduct.categoryIdentifier === "none")
      missingFields.push("categoryIdentifier");
    if (!editProduct.amount) missingFields.push("amount");
    if (!editProduct.package) missingFields.push("package");
    if (!editProduct.baseUnitIdentifier) missingFields.push("baseUnitIdentifier");

    if (missingFields.length > 0) {
      console.log("3", missingFields);
      setMissingFieldsProduct(missingFields);
      return;
    }

    console.log("4");

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveProduct(api, editProduct.identifier, editProduct).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminProducts.popup.product.onSave.success.title"),
          I18n.t("adminProducts.popup.product.onSave.success.text")
        );
        setCategories(response?.body?.categories as IProductCategories);
        setEditProduct(undefined);
        setMissingFieldsProduct([]);
        setUnsavedChangesProduct(false);
      }
    });
    NOTIFICATIONS.hideSaving();
  };

  function updateSearch<P extends keyof PrFormSearchProps, V extends PrFormSearchProps[P]>(property: P, value: V) {
    if (!search) return;
    const updatedSearch = cloneDeep(search);
    updatedSearch[property] = value;
    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    const updatedSearch = cloneDeep(initSearch);
    if (search.sortBy) updatedSearch.sortBy = search.sortBy;
    setSearch(updatedSearch);
  };

  const actions: TActionsAdminProducts = {
    selectCategory,
    getFilteredProducts,
    showPopupEditCategory,
    hidePopupEditCategory,
    onEdiCategory,
    onSaveCategory,
    onDeleteCategory,
    showPopupEditProduct,
    hidePopupEditProduct,
    onEditProduct,
    onDeleteProduct,
    onSaveProduct,
    updateSearch,
    searchInProducts,
    resetSearch,
  };

  return {
    categories,
    productUnits,
    suppliers,
    baseUnits,
    selectedCategory,
    editCategory,
    editProduct,
    missingFieldsProduct,
    search,
    actions,
    APP,
  };
};

export default useServiceAdminProducts;
