import * as React from "react";
import Icon from "../Icon";
import TabsWrapper from "./TabsWrapper";
import TabsContent from "./TabsContent";

export interface ITabsTab {
  label?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  badge?: string | number | React.ReactNode;
  hideOnNew?: boolean;
  hideOnEdit?: boolean;
  disabled?: boolean;
}

export type ITabs = { [key: string]: ITabsTab };

interface ITabsProps {
  vertical?: boolean;
  pills?: boolean;
  tabs: ITabs;
  selected: string;
  isNew?: boolean;
  editMode?: boolean;
  updateHistory?: boolean;
  onSelect: (tabKey: string) => void;
  onUpdateUrl?: (params?: string) => void;
  children?: React.ReactNode;
}

const Tabs: React.FunctionComponent<ITabsProps> = ({
  vertical,
  pills,
  tabs,
  selected,
  isNew,
  editMode,
  updateHistory,
  onSelect,
  onUpdateUrl,
  children,
}) => {
  const [tabsLoaded, setTabsLoaded] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");

    if (tab && tab in tabs) onSelect(tab);
  }, [window.location.search]);

  React.useEffect(() => {
    if (!updateHistory) return;

    if (tabsLoaded !== true) {
      setTabsLoaded(true);
    } else {
      const params = new URLSearchParams(window.location.search);

      params.set("tab", selected);
      if (onUpdateUrl) onUpdateUrl(params.toString());
    }
  }, [selected]);

  React.useEffect(() => {
    if (editMode !== true) return;

    if (tabs[selected].hideOnEdit !== true) return;

    let firstTabWithoutHidingOnEdit: string | null = null;

    for (const tabKey of Object.keys(tabs)) {
      if (!tabs[tabKey].hideOnEdit && !firstTabWithoutHidingOnEdit) firstTabWithoutHidingOnEdit = tabKey;
    }

    if (firstTabWithoutHidingOnEdit) onSelect(firstTabWithoutHidingOnEdit);
  }, [editMode]);

  const viewTabs = (
    <div className={`__tabs ${pills === true ? `pills` : `${vertical === true ? `vertical` : `horizontal`}`}`}>
      {Object.keys(tabs).map((tabKey, index) => {
        const tab = tabs[tabKey];

        if (isNew === true && tab.hideOnNew === true) return null;
        if (editMode === true && tab.hideOnEdit === true) return null;

        let icon;
        if (tab.icon) {
          if (typeof tab.icon === "string") icon = <Icon icon={tab.icon} />;
          else icon = tab.icon;
        }
        icon = <span className="__tabs-tab-icon">{icon}</span>;

        let badge;
        if (tab.badge) {
          badge = <span className="__tabs-tab-badge">{tab.badge}</span>;
        }

        return (
          <button
            key={index}
            className={`__tabs-tab ${tab.disabled === true ? `disabled` : ``} ${tabKey === selected ? `selected` : ``}`}
            onClick={() => {
              if (!tab.disabled) onSelect(tabKey);
            }}
          >
            {tab?.icon && icon}
            {tab?.label}
            {badge}
          </button>
        );
      })}
    </div>
  );

  // Add Wrapper if <Tabs /> has children
  if (children)
    return (
      <TabsWrapper vertical={vertical}>
        {viewTabs}
        <TabsContent vertical={vertical}>{children}</TabsContent>
      </TabsWrapper>
    );
  else return viewTabs;
};

export default Tabs;
