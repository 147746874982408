import React from "react";
import { IClients } from "src/types/clients.types";
import * as FDN from "src/core";
import ClientsList from "./ClientsList";
import { SearchSort } from "src/types/types";
import { sortOptions } from "src/services/admin/DashboardService";
import { cloneDeep } from "lodash";

interface IDashboardClientsProps {
  clients: IClients;
}

export default function DashboardClients({ clients }: IDashboardClientsProps) {
  const [sortBy, setSortBy] = React.useState<SearchSort>("clientNr_asc");

  const onSortSelect = (option: SearchSort) => {
    setSortBy(option);
  };

  const getSortedClients = () => {
    if (!clients) return [];
    if (!sortBy) return clients;

    return cloneDeep(clients).sort((a, b) => {
      if (sortBy === "clientNr_asc") return (a.clientNr ?? "").localeCompare(b.clientNr ?? "");
      if (sortBy === "clientNr_desc") return (b.clientNr ?? "").localeCompare(a.clientNr ?? "");
      if (sortBy === "clientName_asc") return (a.name ?? "").localeCompare(b.name ?? "");
      if (sortBy === "clientName_desc") return (b.name ?? "").localeCompare(a.name ?? "");
      if (sortBy === "openPRs_asc") return (a.openPRs ?? 0) - (b.openPRs ?? 0);
      if (sortBy === "openPRs_desc") return (b.openPRs ?? 0) - (a.openPRs ?? 0);
      return 0;
    });
  };

  return (
    <div>
      <h2>{FDN.I18n.t("adminDashboard.clients.title")}</h2>
      <ClientsList clients={getSortedClients()} sortBy={sortBy} sortOptions={sortOptions} sortOnSelect={onSortSelect} />
    </div>
  );
}
