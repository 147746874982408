import * as React from "react";
import Label from "./components/Label";
import ShowValue from "./components/ShowValue";
import InputCountry from "./inputtypes/InputCountry";
import InputEmail from "./inputtypes/InputEmail";
import InputI18nText from "./inputtypes/InputI18nText";
import InputPassword from "./inputtypes/InputPassword";
import InputText from "./inputtypes/InputText";
import { IInputProps } from "./types";
import InputCheckbox from "./inputtypes/InputCheckbox";
import InputYesNoSelect from "./inputtypes/InputYesNoSelect";
import InputTextarea from "./inputtypes/InputTextarea";
import InputHtmlCode from "./inputtypes/InputHtmlCode";
import InputDatepicker from "./inputtypes/InputDatepicker";
import InputSelect from "./inputtypes/InputSelect";
import InputCurrency from "./inputtypes/InputCurrency";
import { formatNumber } from "../../../helpers/numbers";
import InputNumber from "./inputtypes/InputNumber";

const Input: React.FunctionComponent<IInputProps> = (props) => {
  let { type, editMode } = props;
  if (!type) type = "text";
  if (!editMode) editMode = false;

  const options = props.options || { currency: "EUR", locale: "de-DE" };
  //delete props.options;

  const onEdit = (value: any): void => {
    if (props.onUpdate) props.onUpdate(value);
  };

  const onKeyUp = (e: any) => {
    if (e?.key === "Enter" && props.onKeyUp) props.onKeyUp();
  };

  const actions = {
    onEdit,
    onKeyUp,
  };

  if (type === "checkbox") return <InputCheckbox {...props} actions={actions} />;

  return (
    <div
      className={`__ptfdn-input __ptfdn-input-type-${type} __ptfdn-input-editmode-${
        editMode === true ? `active` : `inactive`
      }`}
    >
      {editMode === true ? (
        <>
          {type === "text" && <InputText {...props} options={options} actions={actions} />}
          {type === "number" && <InputNumber {...props} options={options} actions={actions} />}
          {type === "textarea" && <InputTextarea {...props} options={options} actions={actions} />}
          {type === "htmlcode" && <InputHtmlCode {...props} options={options} actions={actions} />}
          {type === "i18ntext" && <InputI18nText {...props} options={options} actions={actions} />}
          {type === "select" && <InputSelect {...props} options={options} actions={actions} />}
          {type === "email" && <InputEmail {...props} options={options} actions={actions} />}
          {type === "password" && <InputPassword {...props} options={options} actions={actions} />}
          {type == "country" && <InputCountry {...props} options={options} actions={actions} />}
          {type == "yesnoselect" && (
            <InputYesNoSelect {...props} options={options} actions={actions} />
          )}
          {type === "date" && <InputDatepicker {...props} options={options} actions={actions} />}
          {type === "currency" && <InputCurrency {...props} options={options} actions={actions} />}
        </>
      ) : (
        <>
          {type === "currency" ? (
            <ShowValue
              type={type}
              value={
                !props.showValue && !props.value
                  ? ``
                  : `${options.currency}${
                      props.showValue
                        ? formatNumber(props.showValue as number)
                        : formatNumber(props.value as number)
                    }`
              }
            />
          ) : (
            <ShowValue type={type} value={props.showValue || props.value} />
          )}
        </>
      )}
      <Label label={props.label} required={props.required} isMissing={props.isMissing} />
    </div>
  );
};

export default Input;
