import * as React from "react";
import { IInputEditProps } from "../types";

const InputPassword: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, placeholder, actions } = props;

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <input
        type="password"
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => actions.onEdit(e.target.value)}
        onKeyUp={actions.onKeyUp}
      />
    </div>
  );
};

export default InputPassword;
