import React, { useState } from "react";

import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import useServiceCore from "../CoreService";
import { I18n } from "src/core";
import { cloneDeep } from "lodash";
import { IClientSector } from "src/types/clients.types";

export interface AdminSectorsActions {
  showPopupEditSector: (supplier: IClientSector | "new") => void;
  onEdit: <P extends keyof IClientSector, V extends IClientSector[P]>(property: P, value: V) => void;
  onDelete: () => void;
  onCancel: () => void;
  onSave: () => void;
}

const useAdminSectors = () => {
  const [sectors, setSectors] = useState<IClientSector[]>();

  const [selectedSector, setSelectedSector] = useState<IClientSector>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.getSectors(api).then((response) => {
      APP.setPageTitle(I18n.t("adminSectors.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setSectors(response?.body?.sectors as IClientSector[]);
      }
    });
  };

  const showPopupEditSector = (supplier: IClientSector | "new") => {
    if (supplier === "new") {
      const virginSector: IClientSector = {
        identifier: "new",
        name: "",
        code: "",
        lastNumber: 0,
      };
      setSelectedSector(cloneDeep(virginSector));
    } else {
      setSelectedSector(cloneDeep(supplier));
    }
  };

  function onEdit<P extends keyof IClientSector, V extends IClientSector[P]>(property: P, value: V) {
    if (!selectedSector) return;

    selectedSector[property] = value;

    setSelectedSector(cloneDeep(selectedSector));
    setUnsavedChanges(true);
  }

  const onDelete = () => {
    if (!selectedSector) return;

    const doDelete = () => {
      AdminApi.deleteSupplier(api, selectedSector.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          setSectors(response?.body?.sector as IClientSector[]);
          setSelectedSector(undefined);
          setUnsavedChanges(false);
          NOTIFICATIONS.hideDialog();

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("adminSectors.onDelete.success.title"),
            I18n.t("adminSectors.onDelete.success.text")
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminSectors.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doDelete(),
        },
      ],
    });
  };

  const onCancel = () => {
    if (!selectedSector) return;

    const doCancel = () => {
      setSelectedSector(undefined);
      setUnsavedChanges(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!selectedSector) return;

    const missingFields: string[] = [];
    if (!selectedSector.code) missingFields.push("code");
    if (!selectedSector.name) missingFields.push("name");

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      return;
    }

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveSector(api, selectedSector.identifier, selectedSector).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        NOTIFICATIONS.showNotification(
          "success",
          I18n.t("adminSectors.onSave.success.title"),
          I18n.t("adminSectors.onSave.success.text")
        );
        setSectors(response?.body?.sectors as IClientSector[]);
        setSelectedSector(undefined);
        setUnsavedChanges(false);
      }
    });
    NOTIFICATIONS.hideSaving();
  };

  const actions: AdminSectorsActions = {
    showPopupEditSector,
    onEdit,
    onDelete,
    onCancel,
    onSave,
  };

  return {
    sectors,
    selectedSector,
    missingFields,
    actions,
    APP,
  };
};

export default useAdminSectors;
