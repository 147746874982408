import * as React from "react";
import * as FDN from "src/core";
import { IProduct, IProductCategories, ISupplier } from "src/types/products.types";
import { TActionsAdminSuppliers } from "src/services/admin/AdminSuppliersService";
import { I18n } from "src/core";
import useServiceCore from "src/services/CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import { showUnit } from "src/core/helpers/pr.helpers";

interface ITabInformationProps {
  supplier: ISupplier;
  missingFields: string[];
  actions: TActionsAdminSuppliers;
}

export default function TabInformation({ supplier, missingFields, actions }: ITabInformationProps) {
  const [categories, setCategories] = React.useState<IProductCategories | undefined>();
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | undefined>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    AdminApi.getSupplierProducts(api, supplier.identifier || "").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setCategories(response?.body?.products as IProductCategories);
      }
    });
  }, []);

  const currency = Config.get("currency") as string;

  if (!categories)
    return (
      <div className="py-12">
        <FDN.Loading box />
      </div>
    );

  return (
    <div>
      <div className="flex justify-between gap-6">
        <div className="flex-1 max-h-[calc(100vh-200px)] overflow-y-auto">
          {categories.map((category) => (
            <div key={category.identifier}>
              <div className="bg-primary text-white font-semibold px-4 py-2">{category.name}</div>
              <div className="">
                {category.products?.map((product) => (
                  <div key={product.identifier}>
                    <button
                      className="cursor-pointer w-full text-left flex justify-between gap-4 px-6 py-3 duration-200 hover:bg-primaryHover border-b border-b-slate-300"
                      onClick={() => setSelectedProduct(product)}
                    >
                      <div className="w-28 flex-shrink-0">{product.productId}</div>
                      <div className="w-16 flex-shrink-0 text-right">{showUnit(product)}</div>
                      <div className="flex-grow">
                        <div className="font-semibold text-primary">{product.name}</div>
                      </div>
                      <div className="w-28 flex-shrink-0 text-right">
                        {product.priceIsVariable ? (
                          <FDN.ToolTip tooltip={FDN.I18n.t("adminProducts.tooltip.priceIsVariable")}>
                            <span style={{ cursor: "help" }}>
                              {currency}
                              {FDN.formatNumber(product.priceUnit || 0)} *
                            </span>
                          </FDN.ToolTip>
                        ) : (
                          <>
                            {currency}
                            {FDN.formatNumber(product.priceUnit || 0)}
                          </>
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex-1">
          {selectedProduct && (
            <div className="border border-slate-400 rounded-lg px-6 py-4">
              <div className="font-semibold">{selectedProduct.productId}</div>
              <div className="font-bold text-lg text-primary -mt-1">{selectedProduct.name}</div>
              <div className="mt-4">
                <div className="font-semibold">{I18n.t("adminProducts.popup.product.form.description.label")}:</div>

                <div className="text-sm text-slate-600">
                  {selectedProduct.description ? selectedProduct.description : "-"}
                </div>
              </div>

              <div className="mt-4">
                <div className="font-semibold">
                  {I18n.t("adminProducts.popup.product.form.internalDescription.label")}:
                </div>
                <div className="text-sm text-slate-600">
                  {selectedProduct.internalDescription ? selectedProduct.internalDescription : "-"}
                </div>
              </div>

              <div className="mt-4 flex justify-between gap-6 items-center">
                <div className="flex-1 flex justify-start">
                  <div>
                    <div className="font-semibold">{I18n.t("adminProducts.popup.product.form.amount.label")}:</div>
                    <div className="text-sm text-slate-600">
                      {selectedProduct.amount} {selectedProduct.unit}
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex justify-end">
                  <div>
                    <div className="font-semibold">{I18n.t("adminProducts.popup.product.form.priceUnit.label")}:</div>
                    <div className="text-sm text-slate-600 text-right">
                      {currency}
                      {FDN.formatNumber(selectedProduct.priceUnit || 0)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="w-max mx-auto">
                  <FDN.Button
                    small
                    primary
                    url={`${UrlService.url("admin.products.index")}?product=${selectedProduct.identifier}`}
                    openInNewTab
                  >
                    {I18n.t("adminSuppliers.products.buttons.showProduct.label")}
                  </FDN.Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
