import * as React from "react";
import * as FDN from "src/core";
import PasswordStrengthBar from "react-password-strength-bar";
import Config from "src/core/Config";

interface IPasswordSetFormProps {
  oldpassword?: string;
  password1: string;
  password2: string;
  onEdit: (property: string, value: string) => void;
}

const PasswordSetForm: React.FunctionComponent<IPasswordSetFormProps> = ({
  oldpassword,
  password1,
  password2,
  onEdit,
}) => {
  return (
    <div className="__auth-passwordsetform">
      <FDN.Box>
        {oldpassword !== undefined && oldpassword !== null && (
          <FDN.SingleRowCell full margin="xy">
            <FDN.Input
              type="password"
              editMode={true}
              value={oldpassword}
              label={FDN.I18n.t("passwordsetform.form.oldpassword.label")}
              onUpdate={(value) => onEdit("oldpassword", value)}
            />
          </FDN.SingleRowCell>
        )}
        <FDN.SingleRowCell full margin="xy">
          <FDN.Input
            type="password"
            editMode={true}
            value={password1}
            label={FDN.I18n.t("passwordsetform.form.password1.label")}
            onUpdate={(value) => onEdit("password1", value)}
          />
          <PasswordStrengthBar
            password={password1}
            minLength={Config.get("auth.passwordMinLength") as number}
            shortScoreWord={FDN.I18n.t("passwordsetform.passwords.strength.scores.tooshort")}
            scoreWords={[
              FDN.I18n.t("passwordsetform.passwords.strength.scores.default"),
              FDN.I18n.t("passwordsetform.passwords.strength.scores.weak"),
              FDN.I18n.t("passwordsetform.passwords.strength.scores.okay"),
              FDN.I18n.t("passwordsetform.passwords.strength.scores.good"),
              FDN.I18n.t("passwordsetform.passwords.strength.scores.strong"),
            ]}
          />
        </FDN.SingleRowCell>
        <FDN.SingleRowCell full margin="xy">
          <FDN.Input
            type="password"
            editMode={true}
            value={password2}
            label={FDN.I18n.t("passwordsetform.form.password2.label")}
            onUpdate={(value) => onEdit("password2", value)}
          />
        </FDN.SingleRowCell>
      </FDN.Box>
    </div>
  );
};

export default PasswordSetForm;
