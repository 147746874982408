import { IPurchaseRequisition } from "src/types/pr.types";
import { IProduct } from "src/types/products.types";

export const getTotalPositions = (pr: IPurchaseRequisition): number => {
  let totalPositions = 0;

  if (pr.products) {
    for (const product of pr.products) {
      if (product.perfectAmount) totalPositions++;
    }
  }

  if (pr.freeItems) {
    for (const freeItem of pr.freeItems) {
      if (freeItem.perfectAmount) totalPositions++;
    }
  }

  return totalPositions;
};

export const showUnit = (product: IProduct): string => {
  return `${product.amount !== 1 ? `${product.amount} x` : ""} ${product.unit}`;
};
