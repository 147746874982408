import React, { useCallback } from "react";
import * as FDN from "src/core";
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from "react-dropzone";
import { AcceptedFileTypes } from "src/types/types";
import { FileUploaderActions } from "../../hooks/useFileUploader";

interface UploaderDropzoneProps {
  view?: "embed" | "button";
  button?: React.ReactNode;
  maxFiles?: number;
  accept?: AcceptedFileTypes;
  actions: FileUploaderActions;
}

const UploaderDropzone = ({ view, button, maxFiles, accept, actions }: UploaderDropzoneProps) => {
  if (!view) view = "embed";

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles) actions.selectFiles(acceptedFiles);
    },
    [actions]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles, accept });

  return <Dropzone view={view} button={button} getRootProps={getRootProps} getInputProps={getInputProps} />;
};

interface DropzoneProps {
  view: "embed" | "button";
  button?: React.ReactNode;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}

const Dropzone = ({ view, button, getRootProps, getInputProps }: DropzoneProps) => {
  if (view === "button" && button)
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {button}
      </div>
    );

  return (
    <div
      {...getRootProps()}
      className="border border-dashed border-gray-300 rounded-md px-4 py-6  cursor-pointer hover:bg-primaryHover"
    >
      <input {...getInputProps()} />
      <div className="flex flex-col justify-center">
        <div className="pb-2 flex justify-center">
          <FDN.Icon icon="upload" />
        </div>
        <div className="text-center text-gray-600 text-sm italic">
          {FDN.I18n.t("pr.freeitems.csv.buttons.upload.label")}
        </div>
      </div>
    </div>
  );
};

export default UploaderDropzone;
