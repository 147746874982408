import * as React from "react";
import { IInputEditProps } from "../types";

const InputYesNoSelect: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, actions, options } = props;

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <select
        value={value === true ? "YES" : "NO"}
        onChange={(e) => actions.onEdit(e.target.value === "YES" ? true : false)}
      >
        <option value={"YES"}>{options?.texts?.yes || "Yes"}</option>
        <option value={"NO"}>{options?.texts?.no || "No"}</option>
      </select>
    </div>
  );
};

export default InputYesNoSelect;
