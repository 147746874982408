import * as React from "react";
import { IInputEditProps } from "../types";

const InputEmail: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, placeholder, actions } = props;

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <input
        type="email"
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => actions.onEdit(e.target.value)}
      />
    </div>
  );
};

export default InputEmail;
