enum StatusCode {
  SUCCESS = "SUCCESS",
  USER_CREATED = "USER_CREATED",
  LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL",
  SAVED_SUCCESSFULLY = "SAVED_SUCESSFULLY",
  VALID_TOKEN = "VALID_TOKEN",
  ERROR = "ERROR",
  NOT_FOUND = "NOT_FOUND",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  INVALID_FORM_DATA = "INVALID_FORM_DATA",
  EMAIL_EXISTS = "EMAIL_EXISTS",
  EMAIL_DOES_NOT_EXIST = "EMAIL_DOES_NOT_EXIST",
  UNAUTHORIZED = "UNAUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  INVALID_LOGIN_DATA = "INVALID_LOGIN_DATA",
  INVALID_TOKEN = "INVALID_TOKEN",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  ERROR_WHILE_SAVING = "ERROR_WHILE_SAVING",
  ERROR_WHILE_DELETING = "ERROR_WHILE_DELETING",
  ERROR_SERVER_NOT_RESPONDING = "ERROR_SERVER_NOT_RESPONDING",
  ERROR_500 = "ERROR_500",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ERROR_FATAL = "ERROR_FATAL",
  ERROR_CRASH = "ERROR_CRASH",
  EDITLOCK_LOCKED = "EDITLOCK_LOCKED",
  EDITLOCK_OWN = "EDITLOCK_OWN",
  EDITLOCK_NOT_NEEDED = "EDITLOCK_NOT_NEEDED",
  EXISTS = "EXISTS",
}

export default StatusCode;
