import * as React from "react";
import Master from "./Master";
import Footer from "./Footer";

interface ILayoutWithoutTopbarProps {
  page: string;
  children?: any;
}

const LayoutWithoutTopbar: React.FunctionComponent<
  ILayoutWithoutTopbarProps
> = (props) => {
  return (
    <Master page={props.page}>
      <div className="__layout-without-topbar">{props.children}</div>
      <Footer />
    </Master>
  );
};

export default LayoutWithoutTopbar;
