import { useState } from "react";
import PrApi from "src/api/PrApi";
import StatusCode from "src/config/statuscodes";
import useServiceCore from "src/services/CoreService";
import { ProcessedFreeItemsCsvData, TActionsPr } from "src/types/pr.types";
import { TActions } from "src/types/types";

const useFreeItemCsvUpload = (prActions: TActionsPr) => {
  const [view, setView] = useState<"upload" | "review" | "error">("upload");
  const [isLoading, setIsLoading] = useState(false);

  const [records, setRecords] = useState<ProcessedFreeItemsCsvData[]>([]);

  const { api } = useServiceCore();

  const onUploadFinished = (identifier: string) => {
    setView("review");

    setIsLoading(true);

    PrApi.processFreeItemsCsvUpload(api, identifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const records = response?.body?.records as ProcessedFreeItemsCsvData[];
        if (records) {
          setRecords(records);
        } else {
          setView("error");
        }

        setIsLoading(false);
      }
    });
  };

  const goBackToUpload = () => {
    setView("upload");
  };

  const addRecordsToPr = () => {
    prActions.onAddFreeItemsFromCsv(records);
  };

  const actions: TActions = {
    onUploadFinished,
    goBackToUpload,
    addRecordsToPr,
  };

  return { view, isLoading, records, actions };
};

export default useFreeItemCsvUpload;
