import React, { ReactNode } from "react";

interface ITabsWrapper {
  vertical?: boolean;
  children?: ReactNode;
}

const TabsWrapper: React.FunctionComponent<ITabsWrapper> = ({ vertical, children }) => {
  return <div className={`${vertical === true ? `vertical` : ``} __tabs-wrapper`}>{children}</div>;
};

export default TabsWrapper;
