import * as React from "react";
import * as FDN from "src/core";
import TabInformation from "./edit/tabs/TabInformation";
import { IProductBaseUnit } from "src/types/products.types";
import { AdminBaseUnitsActions } from "src/services/admin/useAdminBaseUnits";
import TabProducts from "./edit/tabs/TabProducts";

const tabs: FDN.ITabs = {
  information: {
    label: FDN.I18n.t("adminBaseUnits.edit.tabs.information.label"),
    icon: <FDN.Icon icon="id-card-o" />,
  },
  products: {
    label: FDN.I18n.t("adminBaseUnits.edit.tabs.products.label"),
    icon: <FDN.Icon icon="cubes" />,
  },
};

interface IPopupBaseUnitEditProps {
  baseUnit: IProductBaseUnit;
  missingFields: string[];
  actions: AdminBaseUnitsActions;
}

const PopupBaseUnitEdit: React.FunctionComponent<IPopupBaseUnitEditProps> = ({ baseUnit, missingFields, actions }) => {
  const [selectedTab, setSelectedTab] = React.useState<string>("information");

  return (
    <div className="admin-edit-base-unit">
      <FDN.Popup
        size="small"
        title={baseUnit.identifier === "new" ? FDN.I18n.t("adminBaseUnits.edit.new.title") : baseUnit.name}
        onClose={actions.onCancel}
      >
        {baseUnit.identifier !== "new" && <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />}

        <div className="pt-4">
          {selectedTab === "information" && (
            <TabInformation baseUnit={baseUnit} missingFields={missingFields} actions={actions} />
          )}
          {selectedTab === "products" && <TabProducts baseUnit={baseUnit} />}
        </div>
      </FDN.Popup>
    </div>
  );
};

export default PopupBaseUnitEdit;
