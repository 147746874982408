import * as React from "react";
import * as FDN from "src/core";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import { SearchSort, TActions } from "src/types/types";
import { cloneDeep } from "lodash";
import { IClients } from "src/types/clients.types";
import UrlService from "src/core/UrlService";
import { getAddressInOneLine } from "src/core/helpers/helpers";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 4,
    },
    content: <></>,
  },
  {
    size: { md: 4 },
    textAlign: "left",
    content: FDN.I18n.t("adminDashboard.clients.list.head.clientNr.title"),
  },
  {
    size: { md: 8 },
    textAlign: "left",
    content: FDN.I18n.t("adminDashboard.clients.list.head.name.title"),
  },
  {
    size: { md: 4 },
    textAlign: "center",
    content: FDN.I18n.t("adminDashboard.clients.list.head.openprs.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: undefined,
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

interface IBuildAdminList {
  clients: IClients;
}

const buildAdminList = ({ clients }: IBuildAdminList) => {
  if (!clients) return;

  const adminListSchema = cloneDeep(listSchema);

  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const client of clients) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = `${UrlService.url(`admin.purchaserequisitions.index`)}?text=${client.clientNr}`;

    row.columns[0].content = (
      <div className="admin-clients-list-logo">{client.logoUrl ? <img src={client.logoUrl} alt="" /> : null}</div>
    );
    row.columns[1].content = <div className="text-left">{client.clientNr}</div>;
    row.columns[2].content = (
      <div className="__adminlist-name">
        <div className="__adminlist-name-title">{client.name}</div>
        <div className="__adminlist-name-subtitle">{getAddressInOneLine(client)}</div>
      </div>
    );
    row.columns[3].content = <div className="center-vertically-horizontally">{client.openPRs ?? 0}</div>;

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IClientsListProps {
  clients?: IClients;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const ClientsList: React.FunctionComponent<IClientsListProps> = ({ clients, sortBy, sortOptions, sortOnSelect }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (!clients) return;

    setAdminListSchema(buildAdminList({ clients }));
    setListLoading(false);
  }, [clients]);

  if (!clients) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default ClientsList;
