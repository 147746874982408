import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { classNames } from "src/core/helpers/design.helper";
import { IProduct, IProductBaseUnit } from "src/types/products.types";
import UrlService from "src/core/UrlService";

interface ITabProductsProps {
  baseUnit: IProductBaseUnit;
}

export default function TabProducts({ baseUnit }: ITabProductsProps) {
  const [products, setProducts] = React.useState<IProduct[] | undefined>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    AdminApi.getBaseUnitProducts(api, baseUnit.identifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setProducts(response?.body?.products as IProduct[]);
      }
    });
  }, []);

  if (!products)
    return (
      <div className="py-12">
        <FDN.Loading box />
      </div>
    );

  return (
    <div>
      <div className="text-sm text-gray-500 mb-2">{FDN.I18n.t("adminBaseUnits.edit.products.intro")}</div>

      {products.map((product, index) => (
        <a
          href={`${UrlService.url("admin.products.index")}?product=${product.identifier}`}
          target="_blank"
          rel="noopener noreferrer"
          key={product.identifier}
          className="block hover:bg-primaryHover"
        >
          <div className={classNames("px-4 py-1 flex justify-start gap-2", index !== 0 && "border-t border-gray-200")}>
            <div className="w-6 flex-shrink-0">#{index + 1}</div>
            <div className="font-medium text-gray-500 flex-shrink-0 w-28">{product.productId}</div>
            <div className="font-semibold flex-grow">{product.name}</div>
          </div>
        </a>
      ))}
    </div>
  );
}
