import { cloneDeep } from "lodash";
import * as React from "react";
import { IInputEditProps } from "../types";

type ValueObjectState = { [key: string]: string | null } | null;

const InputI18nText: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, placeholder, actions } = props;

  const [valueObject, setValueObject] = React.useState<ValueObjectState>(null);

  React.useEffect(() => {
    setValueObject(cloneDeep(value));
  }, [value]);

  if (!props.options?.availableLanguages || !valueObject) return null;

  const { availableLanguages } = props.options;

  const onEdit = (language: string, value: string) => {
    valueObject[language] = value;
    actions.onEdit(valueObject);
  };

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      {Object.keys(availableLanguages).map((languageKey) => {
        return (
          <div className="__ptfdn-i18n-input-edit" key={languageKey}>
            <span className="__ptfdn-i18n-input-edit-language-icon">
              {availableLanguages[languageKey].icon}
            </span>
            <input
              type="text"
              value={valueObject[languageKey] || ""}
              placeholder={placeholder}
              onChange={(e) => onEdit(languageKey, e.target.value)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default InputI18nText;
