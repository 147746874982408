import * as React from "react";
import { ILabelProps } from "../types";

const Label: React.FunctionComponent<ILabelProps> = (props) => {
  return (
    <div className={`__ptfdn-input-label ${props.isMissing === true ? `missing` : ``}`}>
      {props.label}
      <Suffix required={props.required} />
    </div>
  );
};

interface ISuffixProps {
  required?: boolean;
}

const Suffix = ({ required }: ISuffixProps) => {
  if (required === true)
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: "*",
        }}
      />
    );
  else
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: "",
        }}
      />
    );
};

export default Label;
