import * as React from "react";
import * as FDN from "src/core";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import { TActions, IUserrolesState, IAccountState } from "src/types/types";
import { cloneDeep } from "lodash";
import { userRolesRoleStatus } from "src/core/AdminService/helpers";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 12,
    },
    content: FDN.I18n.t("adminStaff.list.head.name.title"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: FDN.I18n.t("adminStaff.list.head.manager.title"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: FDN.I18n.t("adminStaff.list.head.employee.title"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: FDN.I18n.t("adminStaff.list.head.clients.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: undefined,
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

interface IBuildAdminList {
  staffs: IAccountState[];
  actions: TActions;
  editable?: boolean;
  selectable?: boolean;
  selected?: string[];
  showMembersCount?: boolean;
}

const buildAdminList = ({ staffs, actions }: IBuildAdminList) => {
  if (!staffs) return;

  const adminListSchema = cloneDeep(listSchema);

  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const staff of staffs) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => {
      actions.onSelectStaff(staff);
    };

    row.columns[0].content = (
      <div className="flex justify-start gap-4 items-center">
        <FDN.Avatar user={staff} size={38} />
        <div>{staff.displayname}</div>
      </div>
    );
    row.columns[1].content = (
      <div className="center-vertically-horizontally">
        {staff.roles.includes("SMAPLY_MANAGER") && <FDN.Icon icon="check" />}
      </div>
    );
    row.columns[2].content = (
      <div className="center-vertically-horizontally">
        {staff.roles.includes("SMAPLY_EMPLOYEE") && <FDN.Icon icon="check" />}
      </div>
    );
    row.columns[3].content = (
      <div className="center-vertically-horizontally">
        {!staff.roles.includes("SMAPLY_MANAGER") && staff.staffAccessToClients.length}
      </div>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IStaffsListProps {
  staffs?: IAccountState[];
  hidePagination?: boolean;
  actions?: TActions;
}

const StaffsList: React.FunctionComponent<IStaffsListProps> = ({ staffs, hidePagination, actions }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (!staffs || !actions) return;

    setAdminListSchema(buildAdminList({ staffs, actions }));
    setListLoading(false);
  }, [staffs]);

  if (!staffs || !actions) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList schema={adminListSchema} loading={listLoading} hidePagination={hidePagination} />
      )}
    </>
  );
};

export default StaffsList;
