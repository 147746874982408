import * as React from "react";
import Grid from "./Grid";
import Row from "./Row";

interface IGridRowProps {
  full?: boolean;
  fluid?: boolean;
  margin?: string;
  padding?: string;
  gridStyle?: { [key: string]: string | number };
  rowStyle?: { [key: string]: string | number };
  gridClassName?: string;
  rowClassName?: string;
  children?: any;
}

const GridRow: React.FunctionComponent<IGridRowProps> = (props) => {
  const {
    full,
    fluid,
    margin,
    padding,
    gridStyle,
    rowStyle,
    gridClassName,
    rowClassName,
    children,
  } = props;

  return (
    <Grid full={full} fluid={fluid} className={gridClassName} style={gridStyle}>
      <Row
        margin={margin}
        padding={padding}
        className={rowClassName}
        style={rowStyle}
      >
        {children}
      </Row>
    </Grid>
  );
};

export default GridRow;
