import * as React from "react";
import * as FDN from "src/core";
import { ISupplier } from "src/types/products.types";
import { TActionsAdminSuppliers } from "src/services/admin/AdminSuppliersService";

interface ITabInformationProps {
  supplier: ISupplier;
  missingFields: string[];
  actions: TActionsAdminSuppliers;
}

export default function TabInformation({ supplier, missingFields, actions }: ITabInformationProps) {
  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <h3>{FDN.I18n.t("adminSuppliers.edit.form.groups.address.title")}</h3>
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.supplierId}
                      label={FDN.I18n.t("adminSuppliers.edit.form.supplierId.label")}
                      isMissing={missingFields.includes("supplierId")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("supplierId", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.name}
                      label={FDN.I18n.t("adminSuppliers.edit.form.name.label")}
                      placeholder={FDN.I18n.t("adminSuppliers.edit.form.name.placeholder")}
                      isMissing={missingFields.includes("name")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("name", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.street}
                      label={FDN.I18n.t("adminSuppliers.edit.form.street.label")}
                      isMissing={missingFields.includes("street")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("street", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.streetAdditional}
                      label={FDN.I18n.t("adminSuppliers.edit.form.streetAdditional.label")}
                      isMissing={missingFields.includes("streetAdditional")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("streetAdditional", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={8}>
                    <FDN.Input
                      type="text"
                      value={supplier.zip}
                      label={FDN.I18n.t("adminSuppliers.edit.form.zip.label")}
                      isMissing={missingFields.includes("zip")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("zip", value)}
                    />
                  </FDN.Cell>
                  <FDN.Cell md={16}>
                    <FDN.Input
                      type="text"
                      value={supplier.city}
                      label={FDN.I18n.t("adminSuppliers.edit.form.city.label")}
                      isMissing={missingFields.includes("city")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("city", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="country"
                      value={supplier.country}
                      label={FDN.I18n.t("adminSuppliers.edit.form.country.label")}
                      isMissing={missingFields.includes("country")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("country", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
          </FDN.Cell>
          <FDN.Cell md={12}>
            <h3>{FDN.I18n.t("adminSuppliers.edit.form.groups.contact.title")}</h3>
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.contactName}
                      label={FDN.I18n.t("adminSuppliers.edit.form.contactName.label")}
                      isMissing={missingFields.includes("contactName")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("contactName", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="text"
                      value={supplier.contactPhone}
                      label={FDN.I18n.t("adminSuppliers.edit.form.contactPhone.label")}
                      isMissing={missingFields.includes("contactPhone")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("contactPhone", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="email"
                      value={supplier.contactEmail}
                      label={FDN.I18n.t("adminSuppliers.edit.form.contactEmail.label")}
                      isMissing={missingFields.includes("contactEmail")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("contactEmail", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
            <h3>{FDN.I18n.t("adminSuppliers.edit.form.groups.notes.title")}</h3>
            <FDN.Box>
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell md={24}>
                    <FDN.Input
                      type="textarea"
                      value={supplier.notes}
                      label={FDN.I18n.t("adminSuppliers.edit.form.notes.label")}
                      isMissing={missingFields.includes("notes")}
                      editable={true}
                      editMode={true}
                      onUpdate={(value) => actions.onEdit("notes", value)}
                    />
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </FDN.Box>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        onCancel={actions.onCancel}
        onSave={actions.onSave}
        onDelete={supplier.identifier !== "new" ? actions.onDelete : undefined}
      />
    </>
  );
}
