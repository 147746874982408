import * as React from "react";
import { IInputEditProps } from "../types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";

registerLocale("de", de);

const InputDatepicker: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, actions, options } = props;

  const [dateObj, setDateObj] = React.useState<Date | null>();

  React.useEffect(() => {
    setDateObj(value ? new Date(value) : null);
  }, [value]);

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <DatePicker
        locale="de"
        selected={dateObj}
        dateFormat={"dd.MM.yyyy"}
        showMonthDropdown
        showYearDropdown
        showWeekNumbers
        isClearable
        todayButton={options?.texts?.today || "Today"}
        onChange={(date) => {
          actions.onEdit(date);
        }}
      />
    </div>
  );
};

export default InputDatepicker;
