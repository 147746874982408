import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import { userHasPermission } from "src/core/AdminService/helpers";
import useAdminSectors from "src/services/admin/useAdminSectors";
import SectorsList from "./parts/SectorsList";
import PopupSectorEdit from "./parts/PopupSectorEdit";

const AdminSectors: React.FunctionComponent = () => {
  const { sectors, selectedSector, missingFields, actions, APP } = useAdminSectors();
  const user = APP.getUser();

  if (!sectors) return <FDN.Loading box />;

  return (
    <div className="__admin-sectors">
      <AdminLayout page="admin-sectors" selectedNav="sectors">
        <AdminHeader
          title={FDN.I18n.t("adminSectors.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button className="primary button" onClick={() => actions.showPopupEditSector("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminSectors.buttons.add.label")}
                </button>
              )}
            </>,
          ]}
        />

        <div className="__box-padding">
          <SectorsList sectors={sectors} actions={actions} />
        </div>

        {selectedSector && <PopupSectorEdit sector={selectedSector} missingFields={missingFields} actions={actions} />}
      </AdminLayout>
    </div>
  );
};

export default AdminSectors;
