import * as React from "react";
import { IInputEditProps } from "../types";
import { isArray, isObject } from "lodash";

const InputSelect: React.FunctionComponent<IInputEditProps> = (props) => {
  const { value, selectValues, actions } = props;

  const selectValuesForMapping: { [key: string | number]: any } = {};

  if (isArray(selectValues)) {
    for (const val of selectValues) {
      selectValuesForMapping[val] = val;
    }
  } else if (isObject(selectValues)) {
    for (const key of Object.keys(selectValues)) {
      selectValuesForMapping[key] = selectValues[key as keyof typeof selectValues];
    }
  }

  return (
    <div className={`${props.isMissing === true ? `missing` : ``} __ptfdn-input-edit`}>
      <select value={value} onChange={(e) => actions.onEdit(e.target.value)}>
        {Object.keys(selectValuesForMapping).map((key) => {
          return (
            <option key={key} value={key}>
              {selectValuesForMapping[key]}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default InputSelect;
