import * as React from "react";
import * as FDN from "src/core";
import { Link } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { hasAccessToAdminArea } from "src/core/AdminService/helpers";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";

const menuKey = "usermenu";

const UserButton: React.FunctionComponent = () => {
  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);
  const user = APP.getUser();

  const showDropdown = NOTIFICATIONS.getMenuStatus(menuKey);

  const toggleDropdownMenu = () => {
    NOTIFICATIONS.toggleMenu(menuKey);
  };

  if (user === null)
    return (
      <Link to={UrlService.url("auth.login")} className="__topbar-login-button">
        Anmelden
      </Link>
    );
  else if (user && user.email)
    return (
      <DropdownWrapper
        user={user}
        showDropdown={showDropdown}
        toggleDropdownMenu={toggleDropdownMenu}
        logout={APP.logout}
      />
    );

  return (
    <div className="__topbar-login-button">
      <FDN.Icon icon="circle-o-notch fa-spin" />
    </div>
  );
};

interface IDropdownWrapperProps {
  user: { [key: string]: any } | null | undefined;
  showDropdown: boolean;
  toggleDropdownMenu: () => void;
  logout: () => void;
}

const DropdownWrapper: React.FunctionComponent<IDropdownWrapperProps> = ({
  user,
  showDropdown,
  toggleDropdownMenu,
  logout,
}) => {
  if (!user) return null;

  const userMenuItems = Config.get("topbar.userMenu.items") as {
    [key: string]: any;
  };

  return (
    <div className="__topbar-dropdown-wrapper">
      {showDropdown === true && (
        <div className="__topbar-dropdown-modal" onClick={toggleDropdownMenu} />
      )}
      <button className="__topbar-dropdown-button" onClick={() => toggleDropdownMenu()}>
        {Config.get("topbar.userName.avatar") === true && <FDN.Avatar user={user} size={40} />}
        {Config.get("topbar.userName.firstname") === true ||
        Config.get("topbar.userName.lastname") === true ? (
          <span
            className="__topbar-dropdown-button-name"
            style={{
              paddingLeft: Config.get("topbar.userName.avatar") === true ? "10px" : "0",
            }}
          >
            {Config.get("topbar.userName.firstname") === true && user.firstname ? (
              <>{user.firstname} </>
            ) : null}
            {Config.get("topbar.userName.lastname") === true && user.lastname ? (
              <>{user.lastname} </>
            ) : null}
          </span>
        ) : null}
      </button>
      {showDropdown === true && (
        <div className="__topbar-dropdown-menu">
          <ul>
            {Object.keys(userMenuItems).map((identifier) => {
              const userMenuItem: { [key: string]: any } = userMenuItems[identifier];

              if (identifier === "logout")
                return (
                  <li key={identifier}>
                    <button onClick={logout}>
                      {userMenuItem.icon && <FDN.Icon icon={userMenuItem.icon} left />}{" "}
                      {FDN.I18n.t(`topbar.items.${identifier}`)}
                    </button>
                  </li>
                );
              else if (identifier === "admin") {
                if (hasAccessToAdminArea(user))
                  return (
                    <li key={identifier}>
                      <Link to={UrlService.url(userMenuItem?.route)}>
                        {userMenuItem.icon && <FDN.Icon icon={userMenuItem.icon} left />}{" "}
                        {FDN.I18n.t(`topbar.items.${identifier}`)}
                      </Link>
                    </li>
                  );
                else return null;
              }

              return (
                <li key={identifier}>
                  <Link to={UrlService.url(userMenuItem?.route)}>
                    {userMenuItem.icon && <FDN.Icon icon={userMenuItem.icon} left />}{" "}
                    {FDN.I18n.t(`topbar.items.${identifier}`)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserButton;
