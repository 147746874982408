import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import { IProductBaseUnit } from "src/types/products.types";
import { AdminBaseUnitsActions } from "src/services/admin/useAdminBaseUnits";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 20 },
    content: FDN.I18n.t("adminBaseUnits.list.head.name.title"),
  },
  {
    size: {
      md: 4,
    },
    textAlign: "center",
    content: FDN.I18n.t("adminBaseUnits.list.head.productCount.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
  rowMargin: "x",
};

const buildAdminList = (baseUnits: IProductBaseUnit[], actions: AdminBaseUnitsActions) => {
  if (!baseUnits) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const baseUnit of baseUnits) {
    if (!baseUnit) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => actions.showPopupEditBaseUnit(baseUnit);

    row.columns[0].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-title">
            <strong>{baseUnit.name}</strong>
          </div>
        </div>
      </>
    );

    row.columns[1].content = <div className="text-center">{baseUnit.productCount ?? 0}</div>;

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface BaseUnitsListProps {
  baseUnits: IProductBaseUnit[];
  actions: AdminBaseUnitsActions;
}

const BaseUnitsList: React.FunctionComponent<BaseUnitsListProps> = ({ baseUnits, actions }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (baseUnits) {
      setAdminListSchema(buildAdminList(baseUnits as IProductBaseUnit[], actions));
      setListLoading(false);
    }
  }, [baseUnits]);

  if (!baseUnits) return <FDN.Loading box />;

  return <>{adminListSchema && <FDN.AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default BaseUnitsList;
