import * as React from "react";
import * as FDN from "src/core";
import { PrFormSearchProps, PrFormSortBy, TActionsPr } from "src/types/pr.types";
import { IProductCategories, ISuppliers } from "src/types/products.types";

interface IPrFormSearchProps {
  search: PrFormSearchProps;
  categories: IProductCategories;
  suppliers: ISuppliers;
  showSupplier: boolean;
  actions: TActionsPr;
}

const PrFormSearch: React.FunctionComponent<IPrFormSearchProps> = ({
  search,
  categories,
  suppliers,
  showSupplier,
  actions,
}) => {
  let sortByValues: PrFormSortBy[] = [
    "unit_asc",
    "unit_desc",
    "productId_asc",
    "productId_desc",
    "productName_asc",
    "productName_desc",
    "productCategory_asc",
    "productCategory_desc",
    "supplier_asc",
    "supplier_desc",
    "priceUnit_asc",
    "priceUnit_desc",
  ];

  if (!showSupplier) {
    sortByValues = sortByValues.filter(
      (sortByValue) => sortByValue !== "supplier_asc" && sortByValue !== "supplier_desc"
    );
  }

  return (
    <div className="__pro-form-search">
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={showSupplier ? 6 : 12}>
              <input
                type="text"
                value={search.text || ""}
                onChange={(e) => actions.updateSearch("text", e.target.value)}
                placeholder={FDN.I18n.t("pr.search.text.placeholder")}
              />
            </FDN.Cell>
            {showSupplier && (
              <FDN.Cell sm={24} md={6}>
                <select
                  value={search.supplier}
                  onChange={(e) => {
                    const value = e.target.value as string;
                    actions.updateSearch("supplier", value === "none" ? "" : value);
                  }}
                >
                  <option value="none">{FDN.I18n.t("pr.search.supplier.label")}</option>
                  {suppliers.map((supplier) => {
                    return (
                      <option key={supplier.identifier} value={supplier.identifier}>
                        {supplier.name}
                      </option>
                    );
                  })}
                </select>
              </FDN.Cell>
            )}
            <FDN.Cell sm={24} md={6}>
              <select
                value={search.category}
                onChange={(e) => {
                  const value = e.target.value as string;
                  actions.updateSearch("category", value === "none" ? "" : value);
                }}
              >
                <option value="none">{FDN.I18n.t("pr.search.category.label")}</option>
                {categories.map((category) => {
                  if (category.products && category.products.length > 0)
                    return (
                      <option key={category.identifier} value={category.identifier}>
                        {category.name}
                      </option>
                    );
                })}
              </select>
            </FDN.Cell>
            <FDN.Cell sm={24} md={6}>
              <div className="block md:hidden text-center font-semibold text-base">
                {FDN.I18n.t("pr.search.sort.title")}:
              </div>
              <select
                value={search.sortBy}
                onChange={(e) => {
                  const value = e.target.value as PrFormSortBy | "none";
                  if (value !== "none") actions.updateSearch("sortBy", value);
                }}
              >
                <option value="none" disabled>
                  {FDN.I18n.t("pr.search.sort.label")}
                </option>
                {sortByValues.map((sortByValue) => (
                  <option key={sortByValue} value={sortByValue}>
                    {FDN.I18n.t(`pr.search.sort.option.${sortByValue}.label`)}
                  </option>
                ))}
              </select>
            </FDN.Cell>
            {/* <FDN.Cell sm={24} md={4}>
              <button
                className="primary hollow button"
                style={{ width: "100%" }}
                onClick={actions.resetSearch}
              >
                <FDN.Icon icon="refresh" left /> {FDN.I18n.t("pr.search.reset.label")}
              </button>
            </FDN.Cell> */}
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
      {/* <div style={{ marginBottom: "10px" }}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={6} mdo={18}>
              <select
                value={search.sortBy}
                onChange={(e) => {
                  const value = e.target.value as PrFormSortBy | "none";
                  if (value !== "none") actions.updateSearch("sortBy", value);
                }}
              >
                <option value="none" disabled>
                  {FDN.I18n.t("pr.search.sort.label")}
                </option>
                {sortByValues.map((sortByValue) => (
                  <option key={sortByValue} value={sortByValue}>
                    {FDN.I18n.t(`pr.search.sort.option.${sortByValue}.label`)}
                  </option>
                ))}
              </select>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </div> */}
    </div>
  );
};

export default PrFormSearch;
