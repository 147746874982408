import * as React from "react";
import * as FDN from "src/core";

const steps = [1, 10, 20, 50, 100, 500];

interface ISelectPerPageProps {
  selected: number;
  onSelect?: (perPage: number) => void;
}

const SelectPerPage: React.FunctionComponent<ISelectPerPageProps> = (props) => {
  const [value, setValue] = React.useState(props.selected);

  const onSelect = (newValue: number) => {
    setValue(newValue);
    if (props.onSelect) props.onSelect(newValue);
  };

  return (
    <div className="__adminlist-selectperpage">
      <select value={value} onChange={(e) => onSelect(parseInt(e.target.value))}>
        <option value="" disabled>
          {FDN.I18n.t("adminAdmin.selectPerPage.title")}
        </option>
        {steps.map((step) => (
          <option value={step} key={step}>
            {step}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectPerPage;
