import * as React from "react";
import * as FDN from "src/core";
import useServiceAdminProducts from "src/services/admin/AdminProductsService";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import { userHasPermission } from "src/core/AdminService/helpers";
import ProductsByCategoryList from "./parts/ProductsByCategoryList";
import PopupProductCategoryEdit from "./parts/PopupProductCategoryEdit";
import PopupProductEdit from "./parts/PopupProductEdit";
import CsvPopup from "./parts/csv/CsvPopup";
import { useSearchParams } from "react-router-dom";

const AdminProducts: React.FunctionComponent = () => {
  const [popupCsvVisible, setPopupCvsVisible] = React.useState(false);

  const [searchParams] = useSearchParams();
  const productIdentifier = searchParams.get("product");

  const {
    categories,
    productUnits,
    suppliers,
    baseUnits,
    selectedCategory,
    editCategory,
    editProduct,
    missingFieldsProduct,
    search,
    actions,
    APP,
  } = useServiceAdminProducts();
  const user = APP.getUser();

  React.useEffect(() => {
    if (productIdentifier) {
      const product = categories
        ?.flatMap((category) => category.products)
        .find((product) => product && product.identifier === productIdentifier);
      if (product) {
        actions.showPopupEditProduct(product);
      }
    }
  }, [productIdentifier, categories]);

  if (!categories || !productUnits || !suppliers || !baseUnits) return <FDN.Loading box />;

  return (
    <div className="__admin-products">
      <AdminLayout page="admin-products" selectedNav="products">
        <AdminHeader
          title={FDN.I18n.t("adminProducts.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button className="primary hollow button" onClick={() => setPopupCvsVisible(true)}>
                  <FDN.Icon icon="upload" left /> {FDN.I18n.t("adminProducts.buttons.importCsv.label")}
                </button>
              )}
            </>,
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button className="primary hollow button" onClick={() => actions.showPopupEditCategory("new")}>
                  <FDN.Icon icon="folder-o" left /> {FDN.I18n.t("adminProducts.buttons.addCategory.label")}
                </button>
              )}
            </>,
            <>
              {userHasPermission(user, "adminProductsAdd") && (
                <button className="primary button" onClick={() => actions.showPopupEditProduct("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminProducts.buttons.add.label")}
                </button>
              )}
            </>,
          ]}
        />
        <ProductsByCategoryList
          categories={categories}
          selectedCategory={selectedCategory}
          search={search}
          actions={actions}
        />
        {editCategory && <PopupProductCategoryEdit category={editCategory} actions={actions} />}
        {editProduct && (
          <PopupProductEdit
            product={editProduct}
            missingFieldsProduct={missingFieldsProduct}
            productUnits={productUnits}
            suppliers={suppliers}
            baseUnits={baseUnits}
            categories={categories}
            actions={actions}
          />
        )}
        {popupCsvVisible === true ? (
          <CsvPopup categories={categories} onClose={() => setPopupCvsVisible(false)} />
        ) : null}
      </AdminLayout>
    </div>
  );
};

export default AdminProducts;
