import * as React from "react";
import * as FDN from "src/core";
import { Link } from "react-router-dom";

export interface IAdminBackButton {
  label?: string | React.ReactNode;
  url: string;
}

const AdminBackButton: React.FunctionComponent<IAdminBackButton> = ({ label, url }) => {
  if (!label) label = FDN.I18n.t("adminAdmin.backButton.label");

  return (
    <Link to={url} className="__admin-backbutton">
      <FDN.Icon icon="angle-double-left" /> {label}
    </Link>
  );
};

export default AdminBackButton;
