import { cloneDeep } from "lodash";
import * as React from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FileUploadFile } from "./types";

interface IImageCropProps {
  image: FileUploadFile;
  onCrop: (file: FileUploadFile) => void;
}

export interface CropOptions {
  aspect?: number;
  keepSelection?: boolean;
}

const defaultCrop: Crop = {
  unit: "px",
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

const ImageCrop: React.FunctionComponent<IImageCropProps> = (props) => {
  const [image, setImage] = React.useState<FileUploadFile>(props.image);
  const [crop, setCrop] = React.useState<Crop>();
  const [cropOptions, setCropOptions] = React.useState<CropOptions>({});
  const [imageRef, setImageRef] = React.useState();

  const { onCrop } = props;

  React.useEffect(() => {
    if (image.cropOptions) setCropOptions(cloneDeep(image.cropOptions));
  }, []);

  const onImageLoad = (e: any) => {
    if (imageRef) return;

    const imageCrop = cloneDeep(image.crop) || cloneDeep(defaultCrop);
    if (imageCrop) setCrop(imageCrop);

    try {
      if (image.crop && image.cropOptions?.aspect) {
        const imageHeight = e.currentTarget.height as number;
        const imageWidth = e.currentTarget.width as number;

        if (imageHeight > imageWidth) {
          imageCrop.width = imageWidth;
          imageCrop.height = imageWidth / image.cropOptions.aspect;
        } else {
          imageCrop.height = imageHeight;
          imageCrop.width = imageHeight / image.cropOptions.aspect;
        }

        setCrop(cloneDeep(imageCrop));
      }
    } catch {
      //
    }
    setImageRef(e.currentTarget);
  };

  const onChange = (crop: Crop) => {
    setCrop(crop);
  };

  const onComplete = (crop: Crop) => {
    image.crop = crop;
    createCropBlob(imageRef);
  };

  const createCropBlob = async (imageRef: any) => {
    const { crop } = image;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!imageRef || !crop || crop.width === 0 || crop.height === 0 || !ctx) {
      delete image.croppedImage;
      return;
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const cropDataUrl = canvas.toDataURL();
    if (cropDataUrl) {
      image.croppedDataUrl = cropDataUrl;
      image.croppedImage = await (await fetch(cropDataUrl)).blob();

      setImage({ ...image });
      onCrop(image);
    }
  };

  if (!image.original) return null;

  return (
    <ReactCrop crop={crop} onChange={onChange} onComplete={onComplete} {...cropOptions}>
      <img src={URL.createObjectURL(image.original)} onLoad={onImageLoad} />
    </ReactCrop>
  );
};

export default ImageCrop;
