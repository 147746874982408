import React, { useContext, useEffect, useState } from "react";
import * as FDN from "src/core";
import {
  FUpdateEditLock,
  FFatalError,
  IFatalError,
  IEditLockStatus,
  IDialog,
  FShowDialog,
  FShowSaving,
  ISavingInfo,
  ISavingError,
  FSavingError,
  FGetUserNotifications,
  FUpdateValue,
  FToggleMenu,
  IMenuStatus,
  FGetMenuStatus,
} from "./types";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Config from "src/core/Config";

import {
  NOTIFICATION_TYPE,
  NOTIFICATION_CONTAINER,
} from "react-notifications-component/dist/src/typings";
import { cloneDeep } from "lodash";
import AdminApi from "src/api/AdminApi";
import Api from "src/api/Api";
import { AppContext } from "../AppContext/AppContext";
import StatusCode from "src/config/statuscodes";
import { useLocation } from "react-router-dom";
import { SocketContext } from "../SocketContext/SocketContext";

interface INotificationsContextProviderProps {
  children?: any;
}

export interface INotificationsContext {
  updateValue: FUpdateValue;
  menuStatus: IMenuStatus;
  toggleMenu: FToggleMenu;
  getMenuStatus: FGetMenuStatus;
  editLock?: IEditLockStatus | null;
  updateEditLock: FUpdateEditLock;
  showDialog: FShowDialog;
  hideDialog: FShowDialog;
  showError: () => void;
  fatalError: FFatalError;
  showNotification: (type: NOTIFICATION_TYPE, title: string, text: string) => void;
  showSaving: FShowSaving;
  hideSaving: FShowSaving;
  showSavingError: FSavingError;
  getUserNotifications: FGetUserNotifications;
}

const initialContextValue: INotificationsContext = {
  editLock: undefined,
  updateValue: () => null,
  menuStatus: {
    usermenu: false,
    usernotifications: false,
  },
  toggleMenu: () => null,
  getMenuStatus: () => false,
  updateEditLock: () => null,
  showDialog: () => null,
  hideDialog: () => null,
  showError: () => null,
  fatalError: () => null,
  showNotification: () => null,
  showSaving: () => null,
  hideSaving: () => null,
  showSavingError: () => null,
  getUserNotifications: () => undefined,
};

export const NotificationsContext = React.createContext<INotificationsContext>(initialContextValue);

export const NotificationsContextProvider = (props: INotificationsContextProviderProps) => {
  const [contextValue, setContextValue] = useState(initialContextValue);
  const [showFatalError, setShowFatalError] = useState<IFatalError | null>(null);

  const [editLockSetInterval, setEditLockSetInterval] = useState<number>();

  const [dialog, setDialog] = useState<IDialog | null>();

  const [savingInfo, setSavingInfo] = useState<ISavingInfo | null>();
  const [savingError, setSavingError] = useState<ISavingError | null>();

  const APP = useContext(AppContext);
  const NOTIFICATIONS = useContext(NotificationsContext);
  const api = new Api(APP, NOTIFICATIONS);

  const SOCKETIO = useContext(SocketContext);
  const userNotifications = SOCKETIO.getUserNotifications();

  const location = useLocation();

  useEffect(() => {
    updateEditLock(null);

    // Reset menu statuses
    for (const menuKey of Object.keys(contextValue.menuStatus)) {
      contextValue.menuStatus[menuKey as keyof IMenuStatus] = false;
    }
    setContextValue(cloneDeep(contextValue));
  }, [location]);

  const updateValue: FUpdateValue = (key, value) => {
    try {
      contextValue[key as keyof INotificationsContext] = value;
      setContextValue(cloneDeep(contextValue));
    } catch {
      //
    }
  };

  const toggleMenu: FToggleMenu = (key) => {
    if (key in contextValue.menuStatus) {
      contextValue.menuStatus[key as keyof IMenuStatus] =
        !contextValue.menuStatus[key as keyof IMenuStatus];

      for (const menuKey of Object.keys(contextValue.menuStatus)) {
        if (menuKey !== key) contextValue.menuStatus[menuKey as keyof IMenuStatus] = false;
      }
      setContextValue(cloneDeep(contextValue));
    }
  };

  const getMenuStatus: FGetMenuStatus = (key) => {
    if (key in contextValue.menuStatus) return contextValue.menuStatus[key as keyof IMenuStatus];
    return false;
  };

  /**
   * This method gets called from AdmimApi.requestEditLock()
   * If the status of the EditLock is EDITOWN_LOCK, an interval is called
   * to check the status of the EditLock every x seconds (see config.json)
   */
  const updateEditLock = (editLockStatus: IEditLockStatus | null) => {
    if (!editLockStatus) {
      contextValue.editLock = null;
      setContextValue(cloneDeep(contextValue));
      clearInterval(editLockSetInterval);
      return;
    }

    contextValue.editLock = editLockStatus;
    setContextValue(cloneDeep(contextValue));

    if (editLockStatus.status === StatusCode.EDITLOCK_OWN) {
      const editLockTimeInterval = (Config.get("editlock.interval") as number) * 1000;
      setEditLockSetInterval(
        window.setInterval(() => {
          /**
           * Recheck EditLock status
           */
          AdminApi.requestEditLock(
            api,
            NOTIFICATIONS,
            editLockStatus.type || "unknown",
            editLockStatus.affectedIdentifier || "unknown"
          ).then(() => {
            // Nothing
          });
        }, editLockTimeInterval)
      );
    }
  };

  const showDialog: FShowDialog = (dialog?) => {
    if (!dialog) dialog = undefined;
    setDialog(cloneDeep(dialog));
  };

  const hideDialog: FShowDialog = () => {
    setDialog(undefined);
  };

  const showError = () => {
    // Maybe later
  };

  /**
   * Show a fatal error on the screen that stops the whole app.
   */
  const fatalError: FFatalError = (fatalError) => {
    if (!fatalError.date) fatalError.date = new Date();
    setShowFatalError(fatalError);
  };

  /**
   * Show a message at the side of the screen
   */
  const showNotification = (type: NOTIFICATION_TYPE, title: string, text: string) => {
    Store.addNotification({
      title: title,
      message: text,
      type: type,
      insert: "top",
      container: Config.get("notifications.position") as NOTIFICATION_CONTAINER,
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: Config.get("notifications.duration") as number,
        onScreen: Config.get("notifications.onScreen") as boolean,
      },
    });

    // Hide all dialogs if there are any
    hideDialog();
  };

  const showSaving = (savingInfo?: ISavingInfo | null) => {
    setSavingInfo(cloneDeep(savingInfo));
  };

  const hideSaving = () => {
    setSavingError(undefined);
    setSavingInfo(undefined);
  };

  const showSavingError = (savingError?: ISavingError | null) => {
    setSavingError(cloneDeep(savingError));
  };

  const getUserNotifications = () => {
    return userNotifications;
  };

  const context = {
    ...contextValue,
    updateValue,
    toggleMenu,
    getMenuStatus,
    updateEditLock,
    showDialog,
    hideDialog,
    showError,
    fatalError,
    showNotification,
    showSaving,
    hideSaving,
    showSavingError,
    getUserNotifications,
  };

  return (
    <NotificationsContext.Provider value={context}>
      <ReactNotifications />
      {dialog && <FDN.Dialog dialog={dialog} />}
      {savingInfo && (
        <FDN.Saving savingInfo={savingInfo} savingError={savingError} onClose={hideSaving} />
      )}
      {showFatalError && <FDN.ErrorFatalError error={showFatalError} />}
      {props.children}
    </NotificationsContext.Provider>
  );
};
