import * as React from "react";
import SpinnerSvg from "../../../../../assets/icons/Spinner-1s-200px-2.svg";

interface ILoadingProps {
  box?: boolean;
  hideIcon?: boolean;
  hideText?: boolean;
  text?: string | React.ReactNode;
}

const Loading: React.FunctionComponent<ILoadingProps> = (props) => {
  const { hideIcon, hideText } = props;
  //const text = props.text || I18n.t("main.loadingPleaseWait");
  const text = props.text;

  if (props.box && props.box === true) {
    return (
      <div className={`__loading-box`}>
        <div className="__loading-box-content">
          {hideIcon ? null : (
            <div className="__loading-box-icon">
              <img src={SpinnerSvg} alt="" />
              {/*<Icon icon={`circle-o-notch fa-spin`} />*/}
            </div>
          )}
          {hideText ? null : <div className="__loading-box-text">{text}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className="__loading-overlay">
      <div className="__loading-overlay-content">
        {hideIcon ? null : (
          <div className="__loading-overlay-icon">
            <img src={SpinnerSvg} alt="" />
            {/*<Icon icon={`circle-o-notch fa-spin`} />*/}
          </div>
        )}
        {hideText ? null : <div className="__loading-overlay-text">{text}</div>}
      </div>
    </div>
  );
};

export default Loading;
