import * as React from "react";
import { IDialog } from "src/components/context/NotificationsContext/types";
import { castButtons } from "./service";
import * as FDN from "src/core";

interface IDialogProps {
  dialog: IDialog;
}

const Dialog: React.FunctionComponent<IDialogProps> = ({ dialog }) => {
  if (!dialog.type) dialog.type = "okcancel";
  dialog.buttons = castButtons(dialog.buttons, dialog.type);

  const message = dialog.message.replace("\r\n", "<br />");
  return (
    <div className="__dialog">
      <div className="__dialog-modal">
        <div className="__dialog-box">
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <div className="__dialog-buttons">
            {["okcancel", "yesno", "custom"].includes(dialog.type as string) && (
              <DefaultButtons dialog={dialog} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultButtons: React.FunctionComponent<IDialogProps> = ({ dialog }) => {
  return (
    <>
      {dialog.buttons.map((button, index) => {
        return (
          <button
            key={index}
            className={`small primary ${
              ["cancel", "hollow"].includes(button.type as string) && `hollow`
            } button`}
            onClick={() => {
              if (button.onClick) button.onClick();
            }}
          >
            <FDN.Icon icon={button.icon || "question-circle-o"} left />
            {button.label || "?"}
          </button>
        );
      })}
    </>
  );
};

export default Dialog;
