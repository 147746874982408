import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import { userHasPermission } from "src/core/AdminService/helpers";
import useServiceAdminClients from "src/services/admin/AdminClientsService";
import ClientsList from "./parts/ClientsList";
import PopupClientEdit from "./parts/PopupClientEdit";

const AdminClients: React.FunctionComponent = () => {
  const {
    clients,
    editClient,
    missingFields,
    categories,
    selectedCategory,
    sectors,
    originalSectorIdentifier,
    actions,
    APP,
  } = useServiceAdminClients();
  const user = APP.getUser();

  React.useEffect(() => {
    actions.init();
  }, []);

  if (!clients) return <FDN.Loading box />;

  return (
    <div className="__admin-clients">
      <AdminLayout page="admin-clients" selectedNav="clients">
        <AdminHeader
          title={FDN.I18n.t("adminClients.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminAccountsAdd") && (
                <button className="primary button" onClick={() => actions.showPopupEdit("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminClients.buttons.add.label")}
                </button>
              )}
            </>,
          ]}
        />
        {!clients || clients.length === 0 ? (
          <p className="text-center">
            <em>{FDN.I18n.t("adminClients.noClients")}</em>
          </p>
        ) : (
          <ClientsList clients={clients} actions={actions} />
        )}
        {editClient && (
          <PopupClientEdit
            client={editClient}
            clients={clients}
            missingFields={missingFields}
            location="admin"
            categories={categories}
            selectedCategory={selectedCategory}
            sectors={sectors}
            originalSectorIdentifier={originalSectorIdentifier}
            actions={actions}
          />
        )}
      </AdminLayout>
    </div>
  );
};

export default AdminClients;
