import * as React from "react";
import * as FDN from "src/core";

interface IMyAccountHeaderProps {
  page: "myaccount" | "settings";
}

const MyAccountHeader: React.FunctionComponent<IMyAccountHeaderProps> = (props) => {
  return (
    <header>
      {props.page === "myaccount" && <h1>{FDN.I18n.t("myaccount.header.title")}</h1>}
      {props.page === "settings" && <h1>{FDN.I18n.t("usersettings.header.title")}</h1>}
    </header>
  );
};

export default MyAccountHeader;
