import * as React from "react";
import { IFatalError } from "src/components/context/NotificationsContext/types";
import * as FDN from "src/core";

interface IErrorFatalErrorProps {
  error: IFatalError;
}

const ErrorFatalError: React.FunctionComponent<IErrorFatalErrorProps> = ({ error }) => {
  //const [value, setValue] = React.useState(false);

  return (
    <div className="__fatalerror">
      <div className="__fatalerror-popup">
        <div className="pretitle">
          <FDN.Icon icon="exclamation-triangle" /> ERROR
        </div>
        <h1>{error.title}</h1>
        <div className="__fatalerror-popup-text" dangerouslySetInnerHTML={{ __html: error.text }} />
      </div>
    </div>
  );
};

export default ErrorFatalError;
