import React, { ReactNode } from "react";

interface ITabsContent {
  vertical?: boolean;
  children?: ReactNode;
}

const TabsContent: React.FunctionComponent<ITabsContent> = ({ vertical, children }) => {
  return <div className={`${vertical === true ? `vertical` : ``} __tabs-content`}>{children}</div>;
};

export default TabsContent;
