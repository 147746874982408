import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import useServiceAdminDashboard from "src/services/admin/DashboardService";
import AdminHeader from "../layouts/AdminHeader";
import DashboardClients from "./parts/DashboardClients";

const AdminDashboard: React.FunctionComponent = () => {
  const { dashboard } = useServiceAdminDashboard();

  if (!dashboard) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-dashboard" selectedNav="dashboard">
      <AdminHeader title={FDN.I18n.t("adminDashboard.header.title")} />

      <DashboardClients clients={dashboard.clients} />
    </AdminLayout>
  );
};

export default AdminDashboard;
