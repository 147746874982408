import * as React from "react";
import { Link } from "react-router-dom";
import { IAdminListBodyRow, IAdminListSchema } from "./types";
import * as FDN from "src/core";

interface IAdminListBodyProps {
  schema: IAdminListSchema;
  loading?: boolean;
  page?: number;
  paginate?: number;
}

const AdminListBody: React.FunctionComponent<IAdminListBodyProps> = (props) => {
  const { schema, loading, page, paginate } = props;

  if (!schema.body) return null;

  const adminListBody = schema.body;

  let paginateBegin = 1;
  let paginateEnd = adminListBody.rows.length;

  if (paginate && page) {
    paginateBegin = (page - 1) * paginate + 1;
    paginateEnd = paginateBegin + paginate - 1;
  }

  if (loading === true)
    return (
      <div className="__adminlist-body __adminlist-loading">
        <FDN.Icon icon="circle-o-notch fa-spin" />
      </div>
    );

  return (
    <div className="__adminlist-body">
      <div className="__adminlist-body-list">
        {adminListBody.rows.map((row, rowIndex) => {
          const entryNumber = rowIndex + 1;
          if (entryNumber >= paginateBegin && entryNumber <= paginateEnd)
            return <ListRow key={rowIndex} row={row} schema={schema} />;
        })}
      </div>
    </div>
  );
};

interface IListRow {
  row: IAdminListBodyRow;
  schema: IAdminListSchema;
}

const ListRow: React.FunctionComponent<IListRow> = ({ row, schema }) => {
  if (row.onClick) {
    return (
      <button
        onClick={() => {
          if (row.onClick) {
            row.onClick("test");
          }
        }}
        className={`__adminlist-row __adminlist-clickable-row ${row.className}`}
      >
        <ListRowContent row={row} schema={schema} />
      </button>
    );
  } else if (row.url) {
    if (row.newWindow === true)
      return (
        <a href={row.url} className={`__adminlist-row __adminlist-clickable-row ${row.className}`}>
          <ListRowContent row={row} schema={schema} />
        </a>
      );
    else
      return (
        <Link to={row.url} className={`__adminlist-row __adminlist-clickable-row ${row.className}`}>
          <ListRowContent row={row} schema={schema} />
        </Link>
      );
  } else {
    return (
      <div className={`__adminlist-row __adminlist-nonclickable-row ${row.className}`}>
        <ListRowContent row={row} schema={schema} />
      </div>
    );
  }

  return null;
};

interface IListRowContent {
  row: IAdminListBodyRow;
  schema: IAdminListSchema;
}

const ListRowContent: React.FunctionComponent<IListRowContent> = ({ row, schema }) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin={schema.rowMargin}>
        {row.columns.map((column, columnIndex) => {
          let sm = 24;
          let md = 24;
          let lg = 24;

          if (typeof column.size === "number") {
            sm = column.size;
            md = column.size;
            lg = column.size;
          } else {
            if (column.size.sm) {
              sm = column.size.sm;
              md = column.size.sm;
              lg = column.size.sm;
            }
            if (column.size.md) {
              md = column.size.md;
              lg = column.size.md;
            }
            if (column.size.lg) {
              lg = column.size.lg;
            }
          }

          let borderLeftClass = "";
          if (column.borderLeft) {
            if (column.borderLeft === true) borderLeftClass = "border-left-solid";
            else borderLeftClass = `border-left-${column.borderLeft}`;
          }

          let borderRightClass = "";
          if (column.borderRight) {
            if (column.borderRight === true) borderRightClass = "border-right-solid";
            else borderRightClass = `border-right-${column.borderRight}`;
          }

          return (
            <FDN.Cell
              sm={sm}
              md={md}
              lg={lg}
              key={columnIndex}
              className={`__adminlist-cell ${borderLeftClass} ${borderRightClass} ${
                column.textAlign ? `text-${column.textAlign}` : ``
              }`}
            >
              {column.tooltip ? (
                <FDN.ToolTip tooltip={column.tooltip}>{column.content || null}</FDN.ToolTip>
              ) : (
                <>{column.content || null}</>
              )}
            </FDN.Cell>
          );
        })}
      </FDN.Row>
    </FDN.Grid>
  );
};

export default AdminListBody;
