import React from "react";
import useFreeItemCsvUpload from "./useFreeItemCsvUpload";
import MultiUpload from "./views/MultiUpload";
import MultiReview from "./views/MultiReview";
import { TActionsPr } from "src/types/pr.types";

interface TabMultiCSVProps {
  prActions: TActionsPr;
}

export default function TabMultiCSV({ prActions }: TabMultiCSVProps) {
  const { view, isLoading, records, actions } = useFreeItemCsvUpload(prActions);

  return (
    <div>
      {view === "upload" && <MultiUpload actions={actions} view={view} />}
      {view === "error" && <MultiUpload actions={actions} view={view} />}
      {view === "review" && <MultiReview isLoading={isLoading} records={records} actions={actions} />}
    </div>
  );
}
