import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useServiceAdminEmailTemplate from "src/services/admin/EmailTemplateService";
import { AppContext } from "src/components/context/AppContext/AppContext";
import EmailTemplate from "./parts/EmailTemplate";
import UrlService from "src/core/UrlService";
import { useNavigate } from "react-router-dom";

const AdminEmailTemplate: React.FunctionComponent = () => {
  const i18nLanguage = FDN.I18n.getLanguage();

  const [tabs, setTabs] = React.useState<FDN.ITabs>();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(i18nLanguage);

  const { actions, editMode, emailTemplate, placeholders } = useServiceAdminEmailTemplate();

  const navigate = useNavigate();

  const APP = React.useContext(AppContext);

  React.useEffect(() => {
    // Set page title
    if (emailTemplate && emailTemplate[i18nLanguage] && emailTemplate[i18nLanguage].name)
      APP.setPageTitle(emailTemplate[i18nLanguage].name);

    // Fill tabs with available languages
    const appI18n = APP.getI18n();
    if (!appI18n || !appI18n.availableLanguages) return;
    const { availableLanguages } = appI18n;

    const initTabs: FDN.ITabs = {};
    for (const languageKey of Object.keys(availableLanguages)) {
      const tab: FDN.ITabsTab = {
        label: (
          <>
            {availableLanguages[languageKey].icon} {availableLanguages[languageKey].name}
          </>
        ),
      };
      initTabs[languageKey] = tab;
    }
    setTabs(initTabs);
  }, [emailTemplate]);

  if (!tabs || !selectedLanguage || !actions || !emailTemplate) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-emailtemplates" selectedNav="emailtemplates">
      <AdminHeader
        title={emailTemplate[i18nLanguage]?.name}
        backButton={{ url: UrlService.url("admin.emailtemplates.index") }}
      />
      <FDN.Tabs
        updateHistory={false}
        editMode={editMode}
        tabs={tabs}
        selected={selectedLanguage}
        onSelect={(tabKey) => setSelectedLanguage(tabKey)}
        onUpdateUrl={(params) => navigate({ search: params })}
      />
      {editMode === true && <FDN.FormButtons onCancel={actions.onCancel} onSave={actions.onSave} />}
      <FDN.TabsContent>
        <EmailTemplate
          emailTemplate={emailTemplate}
          language={selectedLanguage}
          placeholders={placeholders}
          editMode={editMode}
          actions={actions}
        />
      </FDN.TabsContent>
      {editMode === true && (
        <FDN.FormButtons onCancel={actions.onCancel} onSave={actions.onSave} marginBottom="20px" />
      )}
    </AdminLayout>
  );
};

export default AdminEmailTemplate;
