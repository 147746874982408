import React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { classNames } from "src/core/helpers/design.helper";
import { ProcessedFreeItemsCsvData } from "src/types/pr.types";
import { TActions } from "src/types/types";

interface MultiReviewProps {
  isLoading: boolean;
  records: ProcessedFreeItemsCsvData[];
  actions: TActions;
}

export default function MultiReview({ isLoading, records, actions }: MultiReviewProps) {
  const currency = Config.get("currency") as string;
  return (
    <div>
      <h3 className="mb-2">{FDN.I18n.t("pr.freeitems.csv.view.review.title")}</h3>

      <div className="">
        <div className="pb-2 pt-4 text-primary text-lg">
          <strong>{FDN.I18n.t("pr.freeitems.csv.review.title")}</strong>
        </div>
        <div>{FDN.I18n.t("pr.freeitems.csv.review.intro")}</div>

        {isLoading ? (
          <div className="py-6">
            <FDN.Loading box />
          </div>
        ) : (
          <div className="pt-6 pb-2">
            {records.map((record, index) => {
              return (
                <div
                  className={classNames(
                    "flex flex-row justify-between gap-4 py-1",
                    index % 2 === 0 ? "bg-gray-100" : ""
                  )}
                  key={index}
                >
                  <div className="w-10 text-right"># {index + 1}</div>
                  <div className="flex-grow">{record.name}</div>
                  <div className="w-16 text-right">{record.amount}</div>
                  <div className="w-16 text-left">{record.unit}</div>
                  <div className="w-24 text-right">
                    {FDN.formatNumber(record.priceUnit ?? 0)} {currency}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="pt-4 flex justify-between gap-2">
        <div className="flex-1 flex justify-start">
          <FDN.Button small primary hollow icon="arrow-left" onClick={actions.goBackToUpload}>
            {FDN.I18n.t("pr.freeitems.csv.buttons.back.label")}
          </FDN.Button>
        </div>
        <div className="flex-1 flex justify-end">
          <FDN.Button small primary icon="arrow-right" iconRight onClick={actions.addRecordsToPr}>
            {FDN.I18n.t("pr.freeitems.csv.buttons.addRecordsToPr.label")}
          </FDN.Button>
        </div>
      </div>
    </div>
  );
}
